/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import TabButton from './TabButton';
import TabContent from './TabContent';
import './tutorial.css';

export interface TutoriamModalProps {
  t: (key: string) => string;
}

class TutorialModal extends React.Component<TutoriamModalProps> {
  static defaultProps = {};

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    // As the button is into a relatively positioned element we need to move the modal
    $('#tutorialModal').appendTo('body');
  }

  renderCollapseHelp = (parentId: string, id: string, title: string, content: any) => (
    <div className="card" key={id}>
      <div className="card-header" id={`heading${id}`}>
        <h5 className="mb-0">{title}</h5>
      </div>
      <div className="" aria-labelledby={`heading${id}`} data-parent={parentId}>
        <div className="card-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );

  // eslint-disable-next-line class-methods-use-this
  render() {
    const { t } = this.props;
    const tutorialStrings = t('tutorialStrings:root', { returnObjects: true });
    return (
      <li className="nav-item" data-toggle="modal" data-target="#tutorialModal">
        <a>
          <div>
            <FontAwesomeIcon icon={['fad', 'question-circle']} />
            {t('screens.tutorial.button')}
          </div>
        </a>
        <div
          className="modal fade"
          id="tutorialModal"
          role="dialog"
          aria-labelledby="tutorialHeader"
          aria-hidden="true"
          tabIndex="-1"
        >
          <div
            className="modal-dialog modal-lg"
            style={{
              marginRight: '5vw',
              marginLeft: '5vw',
            }}
          >
            <div
              className="modal-content"
              style={{
                height: '90vh',
                width: '90vw',
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="tutorialHeader">
                  {t('screens.tutorial.title')}
                </h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body"
                style={{
                  height: '100%',
                  overflow: 'hidden',
                  margin: 0,
                  padding: 0,
                }}
              >
                <div className="d-flex" style={{ height: '100%' }}>
                  <ul className="nav flex-column tutorialButtons" id="myTab" role="tablist">
                    {Object.keys(tutorialStrings).map((key) => (
                      <TabButton key={key} name={key} text={tutorialStrings[key].title} />
                    ))}
                  </ul>
                  <div className="tab-content d-flex tutorialContent">
                    {Object.keys(tutorialStrings).map((key) => (
                      <TabContent key={key} name={key}>
                        <h2>{tutorialStrings[key].title}</h2>
                        {tutorialStrings[key].content.map((it, index) =>
                          this.renderCollapseHelp(key, `tutorialModal_${key}_${index}`, it.title, it.content.join(' ')),
                        )}
                      </TabContent>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    );
  }
}

export default withTranslation(['default', 'tutorialStrings'])(TutorialModal);
