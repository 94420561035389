/**
 * @flow
 *
 * @format
 */
import LocalizedStringArray from '../LocalizedStringArray';
import LocalizedString from '../LocalizedString';
import AtlGraphNode from '../AtlGraphNode';
import type { AppAtlObject } from '../AtlObject';
/* ***************************
  CLASS
*************************** */

export type AppOpenedAnswer = AppAtlObject & {
  isGoodAnswer: boolean,
  contentsRecognized: LocalizedStringArray,
  nextMessageId: ?string,
};

export default class AnswerOpened extends AtlGraphNode<AppOpenedAnswer> {
  type: string;

  isGoodAnswer: boolean;

  isDefault: boolean;

  contentsRecognized: LocalizedStringArray;

  nextMessageId: string;

  nextCustom: ?string;

  parentMessageId: string;

  // Contains all the data the editor does not manage yet
  meta: any;

  constructor(json: any) {
    super(json, json instanceof AnswerOpened);
    const {
      id,
      type,
      isGoodAnswer,
      isDefault,
      contentsRecognized,
      nextMessageId,
      nextCustom,
      nodeId,
      parentMessageId,
      pos,
      meta,
      ...newMeta
    } = json;
    this.type = 'answerOpened';
    this.isGoodAnswer = isGoodAnswer;
    this.isDefault = isDefault;
    this.contentsRecognized = new LocalizedStringArray(`${this.id}_contentsRecognized`, contentsRecognized, false);
    this.nextMessageId = nextMessageId;
    this.nextCustom = nextCustom;
    this.nodeId = nodeId && nodeId.length ? nodeId : `${parentMessageId}_${this.id}`;
    this.parentMessageId = parentMessageId;
    if (json instanceof AnswerOpened) {
      this.meta = { ...json.meta, ...newMeta };
    } else {
      this.meta = { ...json.meta, ...newMeta };
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      isGoodAnswer: this.isGoodAnswer,
      isDefault: this.isDefault,
      contentsRecognized: undefined,
      nextMessageId: this.nextMessageId,
      ...this.meta,
    };
    if (this.nextCustom && this.nextCustom.length) {
      res.nextCustom = this.nextCustom;
    }
    if (!this.isDefault) {
      res.contentsRecognized = this.contentsRecognized && this.contentsRecognized.serializeForApp();
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      ...super.serializeInheritedFields(),
      type: this.type,
      isGoodAnswer: this.isGoodAnswer,
      isDefault: this.isDefault,
      contentsRecognized: undefined,
      nextMessageId: this.nextMessageId,
      parentMessageId: this.parentMessageId,
      nextCustom: this.nextCustom,
      ...this.meta,
    };
    if (!this.isDefault) {
      res.contentsRecognized = this.contentsRecognized && this.contentsRecognized.serialize();
    }
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    if (!this.isDefault) {
      res.contentsRecognized = this.contentsRecognized;
    }
    return res;
  }

  hasNextCustom() {
    return !!this.nextCustom;
  }
}
