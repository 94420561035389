/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import { TriggeredItemConditions } from './TriggeredItem';

/* ***************************
  CLASS
*************************** */
export type AppOpenable = {};

export default class OpenableItem extends BaseItem {
  description: LocalizedString;

  validateText: LocalizedString;

  constructor(json: any) {
    if (json instanceof OpenableItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, description, validateText, ...newMeta } = json;
    this.description = new LocalizedString(`${this.id}_description`, description);
    this.validateText = new LocalizedString(`${this.id}_validateText`, validateText);
    this.type = ItemTypes.Openable;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.description = this.description && this.description.serializeForApp();
    res.validateText = this.validateText && this.validateText.serializeForApp();
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.description = this.description && this.description.serialize();
    res.validateText = this.validateText && this.validateText.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.description = this.description;
    res.validateText = this.validateText;
    return res;
  }

  calculateSelfUnlockedValues(startItemId: string) {
    return [...super.calculateSelfUnlockedValues(startItemId), { state: TriggeredItemConditions.Launched }];
  }
}
