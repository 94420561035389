/**
 * @flow
 *
 * @format
 */
import AnecdoteItem from './AnecdoteItem';
import BaseItem, { ItemTypes } from './BaseItem';
import { Discussion } from './discussions';
import DocumentItem from './DocumentItem';
import POIItem from './POIItem';

export type LinkedItem = DocumentItem | Discussion | AnecdoteItem;
export type LinkedItemTypes =
  | typeof ItemTypes.DocumentPOI
  | typeof ItemTypes.DiscussionPOI
  | typeof ItemTypes.AnecdotePOI;

export default class POIGenericBundle extends BaseItem {
  poi: POIItem;

  linkedItem: LinkedItem;

  constructor(json: any, type: LinkedItemTypes) {
    if (json instanceof POIGenericBundle) {
      super(json, true);
    } else {
      super(json, false);
    }
    this.type = type;
    const { poi, linkedItem, ...newMeta } = json;
    this.poi = poi;
    this.linkedItem = linkedItem;
    this.setMeta({ ...json.meta, ...newMeta });
  }
}
