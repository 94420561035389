/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';

export const controlActions = {
  pauseMusic: 'pauseMusic',
  resumeMusic: 'resumeMusic',
  releaseMusic: 'releaseMusic',
};

export type controlActionType = {
  pauseMusic: 'pauseMusic',
  resumeMusic: 'resumeMusic',
  releaseMusic: 'releaseMusic',
};

export default class BackgroundMusicControlsItem extends BaseItem {
  changeVolume: boolean;

  volume: number;

  controlAction: ?controlActionType;

  constructor(json: any) {
    if (json instanceof BackgroundMusicControlsItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { changeVolume, volume, controlAction } = json;
    this.type = ItemTypes.BackgroundMusicControls;
    this.changeVolume = changeVolume;
    this.volume = volume !== undefined ? volume : 100;
    this.controlAction = controlAction;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.changeVolume = this.changeVolume;
    res.volume = this.volume;
    res.controlAction = this.controlAction;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.changeVolume = this.changeVolume;
    res.volume = this.volume;
    res.controlAction = this.controlAction;
    return res;
  }
}
