/**
 * @flow
 * @format
 */

import * as React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import type { Team } from 'src/data/types/TeamType';
import { InputSelect } from 'src/pages/components/inputs';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';
import { teamNameMyTeam } from 'src/store/admin/TeamServiceHelper';
import { numberToDisplay, limitOrMax } from 'src/data/types/TeamType';

type Props = {
  teamInputId: string,
  teams: Array<Team>,
  isEditor: boolean,
  idUser: string,
  showLimitScenarios: boolean,
  updateTeamId: OnboardingServiceHelper.UpdateTeamIdType,
  t: (key: string) => string,
};

const OnboardingTeam = ({ teamInputId, teams, isEditor, idUser, showLimitScenarios, updateTeamId, t }: Props) => {
  return (
    <div className="p-4">
      <p>{t('screens.newScenario.onboarding.positionScreen.selectTeam')}</p>
      <InputSelect
        className="p-4"
        fieldName="teamSelect"
        value={teamInputId}
        values={teams}
        itemToId={(team) => team.uid}
        itemToTitle={(team) =>
          `${teamNameMyTeam(team, idUser, t)} - ${numberToDisplay(team, 'scenarioIds')}${limitOrMax(
            team,
            'scenarioIds',
          )} ${t('screens.admin.teams.scenario')}`
        }
        label={t('screens.newScenario.create.teamSelectLabel')}
        handleChange={(event) => updateTeamId(event.target.value)}
        disableEmptyOption={true}
      />
      {!isEditor && showLimitScenarios && (
        <p className="font-weight-bold pl-2 text-danger">
          {t('screens.newScenario.onboarding.positionScreen.errorTeam')}
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  teamInputId: state.scenario.onboarding.teamInputId,
  idUser: state.user.user && state.user.user.uid,
});

const mapDispatchToProps = {
  updateTeamId: OnboardingServiceHelper.updateTeamId,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(OnboardingTeam);
