/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import type { ObjectMap } from './Shortcuts';

/* ***************************
  CLASS
*************************** */
export type AppGameArea = {};
export type GameArea = [number, number][];
export default class GameAreaItem extends BaseItem {
  area: GameArea;

  title: LocalizedString;

  message: LocalizedString;

  constructor(json: any) {
    if (json instanceof GameAreaItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    this.type = ItemTypes.GameArea;
    if (json) {
      const { type, meta, area, title, message, ...newMeta } = json;
      this.area = area ? [...area] : [];
      this.title = new LocalizedString(`${this.id}_title`, title, false);
      this.message = new LocalizedString(`${this.id}_message`, message, false);
      this.setMeta({ ...json.meta, ...newMeta });
    } else {
      this.area = [];
      this.title = new LocalizedString(`${this.id}_title`, { en: '', fr: '' }, false);
      this.message = new LocalizedString(`${this.id}_message`, { en: '', fr: '' }, false);
      this.setMeta({});
    }
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.area = [...this.area];
    if (this.title && !this.title.isEmpty()) {
      res.title = this.title.serializeForApp();
    }
    if (this.message && !this.message.isEmpty()) {
      res.message = this.message.serializeForApp();
    }
    return res;
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[], startItemId: string) {
    const res = super.checkRelease(items, locales, startItemId);
    if (this.area.length < 3) {
      res.push({
        level: 'error',
        item: this.id,
        message: 'E_AREA_INVALID',
        details: { message: 'Area should be at least 3 points' },
      });
    }
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.area = [...this.area];
    res.title = this.title && this.title.serialize();
    res.message = this.message && this.message.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.title = this.title;
    res.message = this.message;
    return res;
  }
}
