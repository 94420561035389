/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';

import { toast } from 'react-toastify';
import { i18n } from 'src/assets/locales';
import BaseItem from 'src/data/BaseItem';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';

/* eslint-disable no-use-before-define */
export interface BaseItemNodeModelListener extends ItemNodeModelListener<BaseItemNodeModel> {}
/* eslint-enable no-use-before-define */

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

export default class BaseItemNodeModel extends ItemNodeModel<BaseItemNodeModelListener> {
  constructor(reachable: boolean = false, item: BaseItem, isRoot: boolean = false) {
    super(ItemNodeTypes.BaseItem, item.id || generateId(), reachable, isRoot);
    this.item = item;
  }

  updateItem(item: BaseItem) {
    this.item = item;
  }

  getModelId() {
    return this.item.id || this.item.nodeId;
  }

  getId() {
    return this.item.id || this.item.nodeId;
  }

  // eslint-disable-next-line no-unused-vars
  acceptInLinks(outNode: any, port: ItemPortModel, isBeforAdd: boolean) {
    if (this.item && !!this.item.id) {
      return true;
    }
    toast.error(i18n.t('default:screens.scenarioEdition.graph.cantLinkNoId'));
    return false;
  }

  // eslint-disable-next-line no-unused-vars
  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    if (this.item && !!this.item.id) {
      return true;
    }
    toast.error(i18n.t('default:screens.scenarioEdition.graph.cantLinkNoId'));
    return false;
  }

  isRemoveLocked() {
    if (this.item) {
      const isLocked = this.item.isRemoveLocked();
      if (isLocked) {
        toast.warn(i18n.t('default:screens.scenarioEdition.graph.cantRemoveThisNode'));
      }
      return isLocked;
    }
    return false;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In'));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
