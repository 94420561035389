/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { NPC } from 'src/data';
import * as types from './types';
import update from 'immutability-helper';

export type NPCReducerState = {
  npcs: NPC[],
};
export const NPCReducerDefaultState = {
  npcs: [],
};

const reducer = (state: NPCReducerState = NPCReducerDefaultState, action: { type: string, payload: any }) => {
  let newState: NPCReducerState;
  switch (action.type) {
    case types.NPC_CREATE: {
      const { npc } = action.payload;
      if (state.npcs === undefined) {
        newState = { npcs: [], ...state };
      }
      let id = npc.id;
      if (!id) {
        if (npc.name !== undefined) {
          if (npc.cityId !== undefined) {
            id = `NPC_${npc.name}_${npc.cityId}`;
          } else {
            id = `NPC_${npc.name}`;
          }
        } else if (npc.cityId !== undefined) {
          id = `NPC_UNKNOW_${npc.cityId}`;
        } else {
          id = 'NPC_UNKNOW';
        }
      }
      npc.id = id;
      newState = update(state, { npcs: { $push: [new NPC(npc)] } });
      break;
    }
    case types.NPC_UPDATE: {
      const { npcs } = state;
      const { id, npc } = action.payload;
      let newNpcs;
      if (npcs.find((elem) => elem.id === id)) {
        newNpcs = npcs.map((elem) => {
          if (elem.id === id) {
            return npc;
          }
          return elem;
        });
      } else {
        newNpcs = update(npcs, { $push: [new NPC(npc)] });
      }
      newState = { npcs: newNpcs };
      break;
    }
    case types.NPC_REMOVE: {
      const { npcs } = state;
      const { id } = action.payload;
      newState = { npcs: npcs.filter((npc) => npc.id !== id) };
      break;
    }
    case types.NPC_CLEANUP:
      newState = { npcs: [] };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const NPCReducerKey = 'npcs';

const npcsTransform = createTransform(
  (inboundState) => inboundState,
  (outboundState) => {
    const res = [];
    for (let i = 0; i < outboundState.length; i += 1) {
      res.push(new NPC(outboundState[i]));
    }
    return res;
  },
);

const npcsPersistConfig = {
  key: NPCReducerKey,
  storage,
  transforms: [npcsTransform],
};

export default createNamedReducer({
  moduleName: NPCReducerKey,
  reducer: persistReducer(npcsPersistConfig, reducer),
});
