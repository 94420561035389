/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
import { TriggeredItemConditions } from './TriggeredItem';

/* ***************************
  CLASS
*************************** */
export type AppComment = {};

export default class CommentItem extends BaseItem {
  contentText: LocalizedString;

  constructor(json: any) {
    if (json instanceof CommentItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, contentText, ...newMeta } = json;
    this.type = ItemTypes.Comment;
    this.contentText = new LocalizedString(`${this.id}_contentText`, contentText);
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.contentText = this.contentText && this.contentText.serializeForApp();
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.contentText = this.contentText && this.contentText.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.contentText = this.contentText;
    return res;
  }

  calculateSelfUnlockedValues(startItemId: string) {
    const selfUnlockedValues = [{ state: TriggeredItemConditions.Completed }];
    return [...super.calculateSelfUnlockedValues(startItemId), ...selfUnlockedValues];
  }
}
