/**
 * @flow
 *
 * @format
 */
import React from 'react';

const AuthUserContext: Object = React.createContext(null);

export default AuthUserContext;
