/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';

import DiscussionEntryPoint from 'src/data/discussions/DiscussionEntryPoint';
import { ItemNodeModel, ItemNodeTypes, ItemNodeModelListener } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';

/* eslint-disable no-use-before-define */
export interface EntryPointModelListener extends ItemNodeModelListener<EntryPointModel> {}
/* eslint-enable no-use-before-define */

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

export default class EntryPointModel extends ItemNodeModel<EntryPointModelListener> {
  constructor(discussionId: string, name: string, nodeId: string, entrypoint: DiscussionEntryPoint) {
    super('entryPoint', generateId(), true, true);
    this.entrypoint = entrypoint;
    this.discussionId = discussionId;
    this.name = name;
  }

  getId() {
    return this.name;
  }

  // eslint-disable-next-line class-methods-use-this
  acceptInLinks() {
    return false;
  }

  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    const expectedLinks = isBeforeAdd ? 1 : 2;
    return (
      !!this.name &&
      (port.links.length === undefined || port.links.length < expectedLinks) &&
      inNode.type === ItemNodeTypes.Message
    );
  }

  initPorts() {
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
