/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
import type { ObjectMap } from 'src/data';
import * as types from './types';

export type ValidityReducerState = ObjectMap<{
  isValid: boolean,
  alerts: { level: string, message: string, extra: any }[],
}>;
export const ValidityReducerDefaultState = {};

const reducer = (
  prevState: ValidityReducerState = ValidityReducerDefaultState,
  action: { type: string, payload: any },
) => {
  let state;
  switch (action.type) {
    case types.VALIDITY_CLEANUP: {
      state = { ...ValidityReducerDefaultState };
      break;
    }
    case types.VALIDITY_SET_ITEM_VALID: {
      const { itemId, isValid, alerts } = action.payload;
      state = { ...prevState, [itemId]: { isValid, alerts } };
      break;
    }
    default:
      state = prevState;
      break;
  }
  return state;
};

export const ValidityReducerKey = 'validity';

export default createNamedReducer({
  moduleName: ValidityReducerKey,
  reducer,
});
