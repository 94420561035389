/**
 * @flow
 *
 * @format
 */
// eslint-disable-next-line max-classes-per-file
import React from 'react';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Firebase, { withFirebase } from 'src/services/Firebase';
import VisibilitySensor from 'react-visibility-sensor';

import { TabContent } from '../components';
import TeamsScreen from './TeamsScreen';

type Props = {
  firebase: Firebase,
  t: (key: string) => string,
};

// class TeamListEditor extends ListEditor<Team> {}

class TeamsTab extends React.PureComponent<Props> {
  static defaultProps = {};

  render() {
    const { t } = this.props;
    return (
      <TabContent name="teams">
        <>
          <div className="card-header">
            <VisibilitySensor partialVisibility>
              <h3>{t('screens.admin.teams.sectionTitle')}</h3>
            </VisibilitySensor>
          </div>
          <TeamsScreen />
        </>
      </TabContent>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

export default compose(withFirebase, connect(mapStateToProps), withTranslation('default'))(TeamsTab);
