/**
 * @flow
 *
 * @format
 */
import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import storage from 'redux-persist/lib/storage/session';
import type { NPCReducerState } from './npcs';
import { NPCModule, NPCReducerKey, NPCReducerDefaultState } from './npcs';
import type { HeaderReducerState } from './header';
import { HeaderModule, HeaderReducerKey, HeaderReducerDefaultState } from './header';
import type { ItemsReducerState } from './items';
import { ItemsModule, ItemsReducerKey, ItemsReducerDefaultState } from './items';
import type { ValidityReducerState } from './releaseValidity';
import { ValidityModule, ValidityReducerKey, ValidityReducerDefaultState } from './releaseValidity';
import type { OnboardingReducerState } from './onboarding';
import { OnboardingModule, OnboardingReducerKey, OnboardingReducerDefaultState } from './onboarding';

import { HeaderTransform } from './header/HeaderReducer';

export type ScenarioReducerState = {
  header: HeaderReducerState,
  npcs: NPCReducerState,
  items: ItemsReducerState,
  validity: ValidityReducerState,
  onboarding: OnboardingReducerState,
};

const ScenarioReducer = combineReducers({
  [NPCReducerKey]: NPCModule.reducer,
  [ItemsReducerKey]: ItemsModule.reducer,
  [HeaderReducerKey]: HeaderModule.reducer,
  [ValidityReducerKey]: ValidityModule.reducer,
  [OnboardingReducerKey]: OnboardingModule.reducer,
});

export const ScenarioReducerDefaultState = {
  [NPCReducerKey]: NPCReducerDefaultState,
  [ItemsReducerKey]: ItemsReducerDefaultState,
  [HeaderReducerKey]: HeaderReducerDefaultState,
  [ValidityReducerKey]: ValidityReducerDefaultState,
  [OnboardingReducerKey]: OnboardingReducerDefaultState,
};

export const ScenarioReducerKey = 'scenario';

const scenarioPersistConfig = {
  key: ScenarioReducerKey,
  storage,
  transforms: [HeaderTransform],
  stateReconciler: hardSet,
};

/* $FlowFixMe */
export default persistReducer(scenarioPersistConfig, ScenarioReducer);
