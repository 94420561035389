/**
 * @flow
 *
 * @format
 */

import LocalizedString from './LocalizedString';
import type { ObjectMap } from './Shortcuts';

export type FileInfo = {
  name: string,
  contentToUpload?: File,
  version: string,
  ext?: string,
  url?: string,
};

export type StorageFileInfo = { storageName: string, version: string, public?: boolean };

type GenerationConfig = {
  font: string,
  fontSize: number,
  backgroundId: string,
};

export default class LocalizedFile {
  itemId: string;

  fieldName: ?string;

  index: number;

  content: LocalizedString;

  files: ObjectMap<FileInfo>;

  ext: string;

  generationConfig: ?GenerationConfig;

  public: ?boolean;

  constructor(
    itemId: string,
    fieldName?: ?string,
    json:
      | LocalizedFile
      | {
          index: number,
          content?: any,
          files?: { [locale: string]: FileInfo },
          public?: boolean,
        } = { index: 1 },
    isPublic?: boolean,
  ) {
    this.itemId = itemId;
    this.index = json.index;
    this.fieldName = fieldName;
    this.content = new LocalizedString(`${itemId}_${this.index}_file_content`, json.content, false);
    this.generationConfig = json.generationConfig ? { ...json.generationConfig } : undefined;
    this.public = isPublic || (json.public ? json.public : false);
    this.parseFiles(json.files);
  }

  getStorageFileName(locale: string, version: string, ext?: string) {
    let res = this.itemId;
    if (this.fieldName) {
      res = `${res}_${this.fieldName}`;
    }
    if (this.index !== undefined) {
      res = `${res}_${this.index}`;
    }
    res = `${res}.${locale}`;
    if (version) {
      res = `${res}_${version}`;
    }
    let fileExt;
    if (this.files[locale]) {
      fileExt = this.files[locale].ext;
    }
    return `${res}.${ext || fileExt || this.ext || 'undef'}`;
  }

  getCompressedName() {
    let res = this.itemId;
    if (this.fieldName) {
      res = `${res}_${this.fieldName}`;
    }
    if (this.index !== undefined) {
      res = `${res}_${this.index}`;
    }
    return `${res}.${this.ext}`;
  }

  parseFiles(json: any) {
    this.files = {};
    Object.keys(this.content.values).forEach((locale) => {
      const file: FileInfo = { name: '', version: 'v1' };
      if (json && json[locale]) {
        if (json[locale].name) {
          file.name = json[locale].name;
        }
        if (json[locale].url) {
          file.url = json[locale].url;
        }
        if (json[locale].ext) {
          file.ext = json[locale].ext;
        }
        if (json[locale].contentToUpload) {
          file.contentToUpload = json[locale].contentToUpload;
        }
        file.version = json[locale].version || 'v1';
        if (file.name) {
          this.ext = file.name.split('.').pop();
        }
      }
      this.files[locale] = file;
    });
    const defaultFile: FileInfo = { name: '', version: 'v1' };
    if (json && json.default) {
      if (json.default.name) {
        defaultFile.name = json.default.name;
      }
      if (json.default.url) {
        defaultFile.url = json.default.url;
      }
      if (json.default.ext) {
        defaultFile.ext = json.default.ext;
      }
      if (json.default.contentToUpload) {
        defaultFile.contentToUpload = json.default.contentToUpload;
      }
      defaultFile.version = json.default.version || 'v1';
      if (defaultFile.name) {
        this.ext = defaultFile.name.split('.').pop();
      }
    }
    this.files.default = defaultFile;
  }

  hasLocale(locale: string) {
    return this.files[locale];
  }

  addLocale(locale: string) {
    this.content.addLocale(locale);
    this.files[locale] = {
      name: '',
      version: 'v1',
    };
  }

  removeLocale(locale: string) {
    this.content.removeLocale(locale);
    delete this.files[locale];
  }

  flush() {
    this.content.flush();
    Object.keys(this.files).forEach((locale) => {
      this.files[locale] = { name: '', version: 'v1' };
    });
  }

  hasFileToUpload() {
    /* $FlowFixMe: Object.values */
    return Object.values(this.files).find((file: FileInfo) => file.contentToUpload !== undefined);
  }

  hasFile() {
    /* $FlowFixMe: Object.values */
    return Object.values(this.files).find((file: FileInfo) => file.name !== undefined && file.name.length > 0);
  }

  serializeForApp() {
    const res = {};
    if (this.content) {
      res.content = this.content.serializeForApp();
    }
    if (this.files) {
      res.fileName = {};
      Object.keys(this.files).forEach((locale) => {
        if (this.files[locale].name) {
          res.fileName[locale] = this.files[locale].name;
        }
      });
    }
    return res;
  }

  serializeFiles() {
    const res = {};
    Object.keys(this.files).forEach((locale) => {
      if (this.files[locale].name) {
        res[locale] = {
          name: this.files[locale].name,
          version: this.files[locale].version,
        };
        if (this.files[locale].ext) {
          res[locale].ext = this.files[locale].ext;
        }
      }
    });
    return res;
  }

  serialize() {
    const res = {};
    if (this.itemId) {
      res.itemId = this.itemId;
    }
    if (this.fieldName !== undefined) {
      res.fieldName = this.fieldName;
    }
    if (this.index !== undefined) {
      res.index = this.index;
    }
    if (this.content) {
      res.content = this.content.serialize();
    }
    if (this.files) {
      res.files = this.serializeFiles();
    }
    if (this.public) {
      res.public = true;
    }
    if (this.generationConfig) {
      res.generationConfig = { ...this.generationConfig };
      Object.keys(res.generationConfig).forEach(
        (key) => res.generationConfig[key] === undefined && delete res.generationConfig[key],
      );
    }
    return res;
  }

  checkRelease(locales: string[]): { level: string, item: string, message: string, details?: any }[] {
    const res = [];
    // Check that the content is available in all locales
    if (!this.hasLocale('default')) {
      locales.forEach((loc) => {
        if (loc !== 'default' && !this.hasLocale(loc)) {
          res.push({
            level: 'warn',
            item: this.itemId,
            message: 'E_FILE_MISSING_IN_LOCALE',
            details: { message: `No translation for file in ${loc}` },
          });
        }
      });
    }
    return res;
  }

  applyTranslations(translationObj: any) {
    return this.content.applyTranslations(translationObj);
  }

  listStorageFiles(locale?: string) {
    const res = [];
    if (locale) {
      if (this.files[locale]) {
        res.push(this.files[locale].name);
      }
    } else {
      /* $FlowFixMe: Object.values */
      Object.values(this.files).forEach((file: FileInfo) => {
        res.push(file.name);
      });
    }
    return res;
  }

  getFileForLocale(locale: string): ?StorageFileInfo {
    const file = this.files[locale];
    if (file && file.name) {
      return {
        storageName: file.name,
        version: file.version,
        ext: file.ext || this.ext,
        public: this.public || false,
      };
    }
    return undefined;
  }

  getCSVContent() {
    return { ...this.content.values };
  }
}
