/**
 * @flow
 *
 * @format
 */

import { TriggeredItemConditions, type TriggeredItemConditionsType } from '../TriggeredItem';
import AtlGraphNode from '../AtlGraphNode';
import type { AppAtlObject } from '../AtlObject';

/* ***************************
  CLASS
*************************** */
export type AppMessageTriggeredItem = AppAtlObject & {
  timer: ?number,
  newTriggeredCondition: ?TriggeredItemConditionsType,
  newTriggeredConditionValue: ?number,
  disableNotif: ?boolean,
};

export default class MessageTriggeredItem extends AtlGraphNode<AppMessageTriggeredItem> {
  type: string;

  timer: ?number;

  newTriggeredCondition: ?TriggeredItemConditionsType;

  newTriggeredConditionValue: ?number;

  parentMessageId: string;

  disableNotif: ?boolean;

  // Contains all the data the editor does not manage yet
  meta: any;

  constructor(json: any) {
    super(json, json instanceof MessageTriggeredItem);
    const {
      id,
      timer,
      newTriggeredCondition,
      newTriggeredConditionValue,
      type,
      nodeId,
      parentMessageId,
      pos,
      meta,
      ...newMeta
    } = json;
    this.type = 'trigger';
    if (json.timer && typeof json.timer === 'string') {
      this.timer = parseInt(json.timer, 10);
    } else if (typeof json.timer === 'number') {
      this.timer = json.timer;
    }
    this.newTriggeredCondition = newTriggeredCondition;
    if (json.newTriggeredConditionValue && typeof json.newTriggeredConditionValue === 'string') {
      this.newTriggeredConditionValue = parseInt(json.newTriggeredConditionValue, 10);
    } else if (typeof json.newTriggeredConditionValue === 'number') {
      this.newTriggeredConditionValue = json.newTriggeredConditionValue;
    }
    this.nodeId = nodeId || `${parentMessageId}_${this.id}`;
    this.parentMessageId = parentMessageId;
    if (json.disableNotif) {
      this.disableNotif = true;
    }
    if (json instanceof MessageTriggeredItem) {
      this.meta = { ...json.meta, ...newMeta };
    } else {
      this.meta = { ...json.meta, ...newMeta };
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      newTriggeredCondition: this.newTriggeredCondition ? this.newTriggeredCondition : undefined,
      newTriggeredConditionValue: undefined,
      ...this.meta,
    };
    if (this.disableNotif) {
      res.disableNotif = this.disableNotif;
    }
    if (this.timer) {
      res.timer = this.timer;
    }
    if (this.newTriggeredCondition === TriggeredItemConditions.Unlocked) {
      res.newTriggeredConditionValue = this.newTriggeredConditionValue;
    }
    return res;
  }

  serializeInheritedFields() {
    const res = {
      ...super.serializeInheritedFields(),
      type: this.type,
      timer: this.timer,
      disableNotif: this.disableNotif,
      newTriggeredCondition: this.newTriggeredCondition,
      newTriggeredConditionValue: this.newTriggeredConditionValue,
      parentMessageId: this.parentMessageId,
      ...this.meta,
    };
    return res;
  }
}
