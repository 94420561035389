/**
 * @flow
 * @format
 */

import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';

type Props = {
  modalTitle: string,
  contentText: string,
  loadingCreation: boolean,
  onCloseClick: () => void,
  onValidateClick: () => void,
  createButtonLabel: string,
  t: (key: string) => string,
};

const ValidationModal = ({
  modalTitle,
  contentText,
  loadingCreation,
  onCloseClick,
  onValidateClick,
  createButtonLabel,
  t,
}: Props) => {
  return (
    <div
      className="modal fade"
      id="validationModal"
      role="dialog"
      aria-labelledby="validationModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="validationModalLabel">
              {modalTitle}
            </h5>
            <button type="button" className="close" onClick={onCloseClick} disabled={loadingCreation}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
            <p> {contentText}</p>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-danger mr-1"
                onClick={onCloseClick}
                disabled={loadingCreation}
              >
                {t('screens.newScenario.onboarding.cancel')}
              </button>
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={onValidateClick}
                disabled={loadingCreation}
              >
                {createButtonLabel}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(ValidationModal);
