/**
 * @flow
 * @format
 */

import * as React from 'react';

import './styles.css';

const $ = typeof window !== `undefined` ? require('jquery') : null;

export interface CustomModalProps {
  onMounted: ({
    showModal: (content: React.Node, cancelable: boolean, onHide?: () => any, modalClass?: string) => any,
    hideModal: () => any,
  }) => any;
  id: string;
}

type State = {
  content: React.Node,
  cancelable: boolean,
  modalClass: string,
};

export default class CustomModal extends React.Component<CustomModalProps, State> {
  static defaultProps = {};

  state = {
    content: null,
    cancelable: false,
    modalClass: '',
  };

  componentDidMount = () => {
    if (this.props.onMounted) {
      this.props.onMounted({
        showModal: this.showModal,
        hideModal: this.hideModal,
      });
    }
  };

  showModal = (content: React.Node, cancelable: boolean, onHide?: () => any, modalClass?: string) => {
    this.setState({ content, cancelable, modalClass: modalClass || '' });
    if ($) {
      $(`#CustomModal${this.props.id}`).modal();
      $(`#CustomModal${this.props.id}`).on('hidden.bs.modal', onHide);
    }
  };

  hideModal = () => {
    if ($) {
      $(`#CustomModal${this.props.id}`).modal('hide');
    }
  };

  render = () => {
    const { id } = this.props;
    const { content, modalClass } = this.state;
    const modalId = `CustomModal${id}`;
    const contClass = 'ml-auto';
    const containerStyle = {};
    return (
      <div className={contClass} style={containerStyle}>
        <div className="modal fade fs-modal" id={modalId} role="dialog" aria-hidden="true">
          <div className={`modal-dialog modal-dialog-centered ${modalClass || ''}`} role="document">
            <div className="modal-content">{content}</div>
          </div>
        </div>
      </div>
    );
  };
}
