/**
 * @format
 * @flow
 */

import * as React from 'react';
import Loader from './Loader';

const WithLoaderContext = React.createContext(null);

export default WithLoaderContext;

export type WithLoaderProps = {};

export type WithLoaderState = {
  isLoading: boolean,
};

const withLoader = (Component: React.Component<any>) => {
  class WithLoader extends React.Component<WithLoaderProps, WithLoaderState> {
    state = {
      isLoading: false,
    };

    render() {
      return (
        <WithLoaderContext.Provider>
          <Component {...this.props} />
          {this.state.isLoading && <Loader />}
        </WithLoaderContext.Provider>
      );
    }
  }

  return WithLoader;
};

export { withLoader };
