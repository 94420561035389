/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';
/* ***************************
  CLASS
*************************** */
export type AppFailure = {};

export default class FailureItem extends BaseItem {
  message: LocalizedString;

  canRetry: boolean;

  canContinue: boolean;

  constructor(json: any) {
    if (json instanceof FailureItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, message, canRetry, canContinue, ...newMeta } = json;
    this.type = ItemTypes.Failure;
    this.canContinue = canContinue !== '' ? canContinue : false;
    this.canRetry = canRetry !== '' ? canRetry : false;
    this.message = new LocalizedString(`${this.id}_message`, message);
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.canContinue = this.canContinue;
    res.canRetry = this.canRetry;
    res.message = this.message && this.message.serializeForApp();
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.canContinue = this.canContinue;
    res.canRetry = this.canRetry;
    res.message = this.message && this.message.serialize();
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.message = this.message;
    return res;
  }
}
