/**
 * @flow
 *
 * @format
 */
import * as types from './types';

export const setUser = (user: any) => ({
  type: types.USER_SET,
  payload: { user },
});

export const updateConsents = (data: { editorTosAgreed: boolean, editorConfidentialityAgreed: boolean }) => ({
  type: types.USER_UPDATE_CONSENT,
  payload: data,
});

export const setLastEmail = (email: string) => ({
  type: types.LAST_EMAIL_SET,
  payload: { email },
});

export const setMatricule = (matricule: string) => ({
  type: types.MATRICULE_SET,
  payload: { matricule },
});
