/**
 * /* @flow
 *
 * @format
 */

import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedStringArray from './LocalizedStringArray';

/* ***************************
  CLASS
*************************** */
export interface AppUnlockable {
  name: string;
  unlockableId: String;
}

export default class UnlockableItem extends BaseItem implements AppUnlockable {
  name: LocalizedString;

  unlockableId: string;

  constructor(json: any) {
    if (json instanceof UnlockableItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, name, unlockableId, ...newMeta } = json;
    this.name = new LocalizedString(`${this.id}_name`, name);
    this.unlockableId = unlockableId;
    this.type = ItemTypes.Unlockable;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.name = this.name && this.name.serializeForApp();
    res.unlockableId = this.unlockableId;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.name = this.name && this.name.serialize();
    res.unlockableId = this.unlockableId;
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.name = this.name;
    return res;
  }
}
