/**
 * @flow
 * @format
 */
import * as types from './types';

/**
 * Updates the coordinates of the starting POI specified during the onboarding
 */
export const setMarkerStart = ({ latitude, longitude }: { latitude: number, longitude: number }) => ({
  type: types.ONBOARDING_UPDATE_COORDINATES,
  payload: {
    latitude,
    longitude,
  },
});

/**
 * Reset the onboarding values to default
 */
export const resetOnboardingValues = () => ({
  type: types.ONBOARDING_RESET_VALUES,
});

/**
 * Updates the value for the name/mission or pitch of the scenario
 */
export const updateNamePitchMissionScenario = (valueString: string, valueCheckbox: boolean, fieldName: string) => ({
  type: types.ONBOARDING_UPDATE_NAME_PITCH_MISSION,
  payload: {
    valueString,
    valueCheckbox,
    fieldName,
  },
});

/**
 * Updates the value for the year and the subtitles in fr/en
 */
export const updateDepartureScenario = (departure: string) => ({
  type: types.ONBOARDING_UPDATE_DEPARTURE,
  payload: {
    departure,
  },
});

/**
 * Updates the value for the team id input
 */
export const updateTeamId = (teamInputId: string) => ({
  type: types.ONBOARDING_UPDATE_TEAM_ID,
  payload: {
    teamInputId,
  },
});
