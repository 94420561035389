/**
 * @flow
 *
 * @format
 */
import * as types from './types';

export const setSelectedRoom = (roomId?: string) => ({
  type: types.ROOM_SET_ROOM,
  payload: { roomId },
});

export const setSelectedItem = (itemId?: string, nodeId?: string) => ({
  type: types.ROOM_SET_ITEM,
  payload: { itemId, nodeId },
});

export const updateGraphOffset = (x: number, y: number) => ({
  type: types.ROOM_SET_GRAPH_OFFSET,
  payload: { x, y },
});

export const updateGraphZoom = (zoom: number) => ({
  type: types.ROOM_SET_GRAPH_ZOOM,
  payload: { zoom },
});
