/**
 * @flow
 *
 * @format
 */

import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import { FirebaseSingleton, FirebaseHelper } from 'src/services/Firebase';
import { asyncForEach } from 'src/utils';
import type { NPCReducerState } from './NPCReducer';
import type { TranslationItemsType } from '../ScenarioServiceHelper';
import * as actions from './actions';
import NPC from '../../../data/NPC';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# NpcServiceHelper / ${title}`, args);
  }
};

// CLEANUP
// *********************
export type cleanupType = () => (ReduxDispatch) => void;
export const cleanup: cleanupType = () => (dispatch) => {
  logHelperCall('cleanup');
  dispatch(actions.cleanup());
};

export type createNPCType = (
  scenarioId: string,
  values: any,
  saveInFirebase?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const createNPC: createNPCType = (scenarioId, values, saveInFirebase = false) => async (dispatch) => {
  logHelperCall('createNPC', { scenarioId, values });
  dispatch(actions.createNpc(values, !saveInFirebase));
};

export type updateNPCAsyncType = (
  scenarioId: string,
  id: string,
  npc: NPC,
  sendNotif?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const updateNPCAsync: updateNPCAsyncType = (scenarioId, id, npc, sendNotif = true) => async (dispatch) => {
  logHelperCall('updateNPCAsync', { scenarioId, id, npc });
  const shouldUploadFile = npc instanceof NPC && npc.hasFileToUpload();
  if (shouldUploadFile) {
    const version = await FirebaseHelper.getScenarioNextVersionAsync(scenarioId, FirebaseSingleton);
    const localizedFiles = npc.getLocalizedFiles();
    if (localizedFiles && npc) {
      await asyncForEach(localizedFiles, async (img) => {
        await asyncForEach(Object.keys(img.files), async (locale) => {
          const file = img.files[locale];
          if (file.contentToUpload) {
            const ext = file.contentToUpload.name.split('.').pop();
            const url = await FirebaseHelper.pushScenarioEditorAssetAsync(
              scenarioId,
              img.getStorageFileName(locale, version, ext),
              file.contentToUpload,
              FirebaseSingleton,
            );
            // eslint-disable-next-line no-param-reassign
            img.ext = ext;
            file.name = img.getStorageFileName(locale, version, ext);
            file.version = version;
            file.url = url;
            file.ext = ext;
            delete file.contentToUpload;
          }
        });
      });
    }
  }
  dispatch(actions.updateNPCAsync(id, npc));
  if (sendNotif) EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_ITEM_PERSISTED')(dispatch);
};

type applyTranslationsType = (
  scenarioId: string,
  npcs: NPCReducerState,
  translations: TranslationItemsType,
) => (dispatch: ReduxDispatch) => Promise<void>;
export const applyTranslations: applyTranslationsType = (scenarioId, npcs, translations) => async (dispatch) => {
  logHelperCall('applyTranslations', { npcs, translations });
  await asyncForEach(npcs.npcs, async (npc) => {
    const npcTranslations = translations[npc.id];
    if (npcTranslations) {
      npc.applyTranslations(npcTranslations);
      await updateNPCAsync(scenarioId, npc.id, npc)(dispatch);
    }
  });
};

export type removeNPCType = (scenarioId: string, id: string, itemsData: any) => (ReduxDispatch) => Promise<void>;
export const removeNPC: removeNPCType = (scenarioId, id, itemsData) => async (dispatch) => {
  logHelperCall('removeNPC', id);
  if (itemsData) {
    Object.values(itemsData).forEach((item) => {
      if (item && item.id) {
        const idStrings = item.id.split('_');
        if (idStrings[idStrings.length - 1] === 'disc') {
          if (item.npcIds && item.npcIds.includes(id)) {
            throw new Error('Cannot remove this NPC because it is used in at least one discussion');
          }
        }
      }
    });
    dispatch(actions.removeNPC(id));
  }
  // await updateFirebaseEditorData(scenarioId, id)(dispatch);
};

// IMPORT
// *********************
export type importNPCSType = (
  scenarioId: string,
  data: any,
  saveInFirebase?: boolean,
) => (ReduxDispatch) => Promise<void>;
export const importNPCS: importNPCSType = (scenarioId, data, saveInFirebase = false) => async (dispatch) => {
  logHelperCall('importNPCS', { data });
  cleanup()(dispatch);
  if (data) {
    await asyncForEach(Object.keys(data), async (npcId) => {
      const npc = { id: npcId, ...data[npcId] };
      await createNPC(scenarioId, npc, saveInFirebase)(dispatch);
    });
  }
};

// EXPORT
// *********************

export const exportTranslations: exportNPCSType = (state) => {
  logHelperCall('exportTranslations');
  let res = [];
  state.npcs.forEach((item) => {
    res = res.concat(item.getLocalizedStringToTranslateWithPath());
  });
  return res;
};
