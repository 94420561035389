/**
 * @flow
 * @format
 */
import * as React from 'react';
import type { ComponentType } from 'react';
import CustomModal from './CustomModal';

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

const ModalContext: Object = React.createContext(null);

export type withModalProps = {
  showModal: (content: React.Node, cancelable: boolean, onHide?: () => any, modalClass?: string) => any | void,
  hideModal: () => any | void,
};

type State = { callbacks: any, id: string };

// eslint-disable-next-line arrow-parens
const withModal = <PassedProps: {}>(
  WrappedComponent: ComponentType<PassedProps>,
): ComponentType<$Diff<PassedProps, withModalProps>> => {
  class WithModal extends React.Component<PassedProps, State> {
    modaleCallbacks = undefined;

    state = {
      callbacks: {},
      id: '',
    };

    componentDidMount = () => {
      this.setState({ id: generateId() });
    };

    modalMounted = (callbacks: any) => {
      this.setState({ callbacks });
    };

    componentWillUnmount = () => {
      delete this.state.callbacks;
    };

    showModal = (content: React.Node, cancelable: boolean, onHide?: () => any, modalClass?: string = '') => {
      if (this.state.callbacks) {
        this.state.callbacks.showModal(content, cancelable, onHide, modalClass);
      }
    };

    hideModal = () => {
      if (this.state.callbacks) {
        this.state.callbacks.hideModal();
      }
    };

    render() {
      return (
        <ModalContext.Provider>
          <WrappedComponent {...this.props} showModal={this.showModal} hideModal={this.hideModal} />
          <CustomModal id={this.state.id} onMounted={(callbacks) => this.modalMounted(callbacks)} />
        </ModalContext.Provider>
      );
    }
  }

  return WithModal;
};

export default withModal;
