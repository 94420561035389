/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import type { User } from 'src/data/types/UserType';

type ListTeamsUserProps = {
  userDisplayed: User,
  setTeamDisplayed: (key: string) => void,
  titleLabel: string,
  teamsType: string,
};

const numberItemsPerPage = 5;

/**
 * React component displaying the user's team, according to which teams we want to see
 */
const ListTeamsUser = ({ userDisplayed, setTeamDisplayed, titleLabel, teamsType }: ListTeamsUserProps) => {
  const [teamsDisplayed, setTeamsDisplayed] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(0);
  const [oldId, setOldId] = React.useState();

  /**
   * Updates the pages and team displayed
   */
  React.useEffect(() => {
    const startItem = currentPage * numberItemsPerPage;
    const endItem = startItem + numberItemsPerPage;
    if (userDisplayed[teamsType]) {
      setTeamsDisplayed(
        userDisplayed[teamsType].filter((scenarioId, index) => {
          return index >= startItem && index < endItem;
        }),
      );
    } else {
      setTeamsDisplayed([]);
    }
  }, [userDisplayed, currentPage]);

  /**
   * Init the fields input with empty strings
   */
  React.useEffect(() => {
    if (userDisplayed[teamsType]) {
      const newInputsEmpty = {};
      for (let i = 0; i < userDisplayed[teamsType].length; i += 1) {
        newInputsEmpty[userDisplayed[teamsType][i]] = '';
      }
      setLastPage(Math.ceil(userDisplayed[teamsType].length / 5));
    } else {
      setLastPage(0);
    }
    if (oldId !== userDisplayed.uid) {
      setCurrentPage(0);
      setOldId(userDisplayed.uid);
    }
  }, [userDisplayed]);

  const renderPagination = () => {
    const rows = [];
    for (let i = 0; i < lastPage; i += 1) {
      rows.push(
        <li className="page-item">
          <a className="page-link" onClick={() => setCurrentPage(i)}>
            {i + 1}
          </a>
        </li>,
      );
    }
    return rows.map((item) => item);
  };

  return (
    <>
      <h5 className="mt-2 ml-2 mr-2">{titleLabel}</h5>
      {teamsDisplayed.map((team) => (
        <button
          id="clickTeam"
          key={team.uid}
          className="list-group-item list-group-item mb-3 list-group-item-action align-items-start"
          onClick={() => setTeamDisplayed(team)}
        >
          <div className="d-flex justify-content-between" key={team.uid}>
            <h5 className="mb-1">{team.name || team.uid}</h5>
            <small className="text-muted">{team.type}</small>
          </div>
        </button>
      ))}
      <>
        <strong className="mt-3 d-flex justify-content-center">
          {`${numberItemsPerPage * currentPage + 1} - ${numberItemsPerPage * currentPage + teamsDisplayed.length}`}
        </strong>
        <nav className="d-flex justify-content-center">
          <ul className="pagination">
            <li className={`page-item${currentPage === 0 ? ' disabled' : ''}`}>
              <a className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            {renderPagination()}
            <li className={`page-item${lastPage - 1 === currentPage ? ' disabled' : ''}`}>
              <a className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </>
    </>
  );
};

export default ListTeamsUser;
