/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import * as Routes from 'src/constants/routes';
import type { AtlEditorState } from 'src/store';
import { EventsServiceHelper } from 'src/store/events';

import './DiscussionGraphView.css';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export type DiscussionInitWidgetProps = {
  createDiscussion: DiscussionServiceHelper.createDiscussionType,
  scenarioId: string,
  addNotif: EventsServiceHelper.addNotifType,
  prefix: string,
  locale: string,
  npcs: any,
  t: (key: string) => string,
};

type State = {
  isValid: boolean,
  idSuffix: string,
  createdDiscussionId?: string,
  npcIds: string[],
};

class DiscussionInitWidget extends React.PureComponent<DiscussionInitWidgetProps, State> {
  static defaultProps = {
    scenarioId: 'discussion_',
    prefix: '',
    canChangeId: true,
    npcs: [],
  };

  state = {
    idSuffix: '',
    npcIds: [],
    isValid: false,
    createdDiscussionId: undefined,
  };

  handleChange = (event) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    this.setState({ [fieldName]: value });
    const newVal = { ...this.state };
    newVal[fieldName] = value;
    this.updateValidity(newVal);
  };

  updateNPC = (data) => {
    const newVal = [...this.state.npcIds];
    if (newVal.includes(data.id)) {
      newVal.npcIds = newVal.filter((it) => it !== data.id);
    } else {
      newVal.npcIds.push(data.id);
    }
    this.setState({ npcIds: newVal });
    this.updateValidity(newVal);
  };

  updateValidity = (newVal) => {
    let isValid: boolean = true;
    const { idSuffix, npcIds }: { idSuffix: string, npcIds: string[] } = newVal;
    isValid = !!idSuffix && !!idSuffix.length && npcIds.length;
    this.setState({ isValid });
  };

  createDiscussion = () => {
    const { idSuffix, npcIds } = this.state;
    const { prefix, createDiscussion } = this.props;
    const id = prefix + idSuffix;
    if (createDiscussion && npcIds) {
      createDiscussion(id, npcIds);
      this.setState({ createdDiscussionId: id });
    }
  };

  renderNpcButton = (element) => {
    const { npcIds } = this.state;
    const { locale } = this.props;
    const birth = element.birthYear ? element.birthYear : '??';
    const death = element.deathYear ? element.deathYear : '??';
    let buttonClass = 'list-group-item mb-3 list-group-item-action flex-column align-items-start';
    if (npcIds.includes(element.id)) {
      buttonClass += ' active';
    }
    return (
      <div className="col-sm-4" key={element.id}>
        <button id={element.id} className={buttonClass} onClick={() => this.updateNPC(element)}>
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{element.name.valueForLocale(locale)}</h5>
            <small className="text-muted">{`${birth}/${death}`}</small>
          </div>
          <p className="mb-1">{element.brief}</p>
          <small className="text-muted">{element.role}</small>
        </button>
      </div>
    );
  };

  render() {
    const { npcs, prefix, t } = this.props;
    const { idSuffix, isValid, createdDiscussionId } = this.state;
    if (createdDiscussionId) {
      return (
        <Redirect
          to={Routes.SCENARIO_DISCUSSION_EDITION.replace(':scenarioId', prefix).replace(
            ':discussionId',
            createdDiscussionId,
          )}
        />
      );
    }
    return (
      <div className="card bg-light screenBlock">
        <div className="card-header">
          <h3>{t('screens.discussionEdition.initEdition.sectionTitle')}</h3>
        </div>
        <div className="card-body">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                {prefix}
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder={t('screens.discussionEdition.initEdition.idPlaceholder')}
              id="idSuffix"
              onChange={this.handleChange}
              value={idSuffix}
              aria-label="id"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-form mb-3">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="contentType">
                {t('screens.discussionEdition.initEdition.npcLabel')}
              </label>
            </div>
            <div className="input-group mb-3">{npcs && npcs.map((element) => this.renderNpcButton(element))}</div>
          </div>
          <button
            className="btn btn-outline-secondary mb-3"
            type="button"
            id="button-addon2"
            onClick={this.createDiscussion}
            disabled={!isValid}
          >
            {t('general.create')}
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => ({
  npcs: [...state.scenario.npcs.npcs, ...state.configuration.globalNpcs],
  prefix: '',
  scenarioId: `${state.scenario.header.id}_`,
  locale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  createDiscussion: DiscussionServiceHelper.createDiscussionAsync,
  addNotif: EventsServiceHelper.addNotif,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(DiscussionInitWidget);
