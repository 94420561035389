/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';
import { toast } from 'react-toastify';
import { i18n } from 'src/assets/locales';
import BaseItem from 'src/data/BaseItem';
import TriggeredItem from 'src/data/TriggeredItem';
import { ItemNodeModel } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';

/* eslint-disable no-use-before-define */
export interface TriggeredItemNodeModelListener extends ItemNodeModelListener<TriggeredItemNodeModel> {}
/* eslint-enable no-use-before-define */

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

export default class TriggeredItemNodeModel extends ItemNodeModel<TriggeredItemNodeModelListener> {
  item: $Shape<TriggeredItem>;

  constructor(reachable: boolean = false, parent: BaseItem, item: TriggeredItem, isRoot: boolean = false) {
    super('TriggeredItem', item.nodeId || generateId(), reachable, isRoot);
    this.item = { ...item };
    if (!item.nodeId) {
      this.item.nodeId = this.id;
    }
    this.parent = parent;
  }

  getModelId() {
    return this.item.id || this.item.nodeId;
  }

  getId() {
    return this.item.id || this.item.nodeId;
  }

  // eslint-disable-next-line class-methods-use-this
  acceptInLinks(outNode: any, port: ItemPortModel, isBeforeAdd: boolean) {
    const max = isBeforeAdd ? 2 : 1;
    if (Object.values(port.links).length < max) {
      return true;
    }

    const outNodeId = outNode && outNode.item && outNode.item.nodeId;
    const currentLink = Object.values(this.getInPorts()[0].links)[0];
    const targetId =
      currentLink &&
      currentLink.sourcePort.parent &&
      currentLink.sourcePort.parent.item &&
      currentLink.sourcePort.parent.item.nodeId;

    if (targetId && targetId !== outNodeId) {
      toast.error(i18n.t('default:screens.scenarioEdition.graph.cantLinkTrigger'));
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    const max = isBeforeAdd ? 2 : 1;
    if (Object.values(port.links).length < max) {
      return true;
    }
    const inNodeId = inNode && inNode.item && inNode.item.nodeId;
    const currentLink = Object.values(this.getOutPorts()[0].links)[0];
    const targetId =
      currentLink &&
      currentLink.targetPort.parent &&
      currentLink.targetPort.parent.item &&
      currentLink.targetPort.parent.item.nodeId;

    if (targetId && targetId !== inNodeId) {
      toast.error(i18n.t('default:screens.scenarioEdition.graph.cantLinkTrigger'));
    }
    return false;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In'));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
