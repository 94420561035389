/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import type { Team } from 'src/data/types/TeamType';
import type { User } from 'src/data/types/UserType';

import { Loader } from 'src/pages/components';

import EditTeamView from './EditTeamView';
import UserInfoTeams from './UserInfoTeams';
import TeamSearch from './TeamSearch';

type TeamsScreenProps = {
  t: (key: string) => string,
};

const TeamsScreen = ({ t }: TeamsScreenProps) => {
  // The different fields we can search to get teams
  const searchTeamTypes = [
    {
      name: t('screens.admin.teams.typeSearchTeam.id'),
      id: 'id',
    },
    {
      name: t('screens.admin.teams.typeSearchTeam.name'),
      id: 'name',
    },
  ];

  // The different fields we can search to get a user
  const searchUserTypes = [
    {
      name: t('screens.admin.teams.typeSearchUser.id'),
      id: 'id',
    },
    {
      name: t('screens.admin.teams.typeSearchUser.email'),
      id: 'mail',
    },
    {
      name: t('screens.admin.teams.typeSearchUser.collarNumber'),
      id: 'collarNumber',
    },
  ];

  const [searchTeamInput, setSearchTeamInput] = React.useState<string>('');
  const [searchTeamSelect, setSearchTeamSelect] = React.useState<string>(searchTeamTypes[0].id);

  const [searchUserInput, setSearchUserInput] = React.useState<string>('');
  const [searchUserSelect, setSearchUserSelect] = React.useState<string>(searchUserTypes[0].id);

  const [teamDisplayed, setTeamDisplayed] = React.useState<?Team>();
  const [otherTeamToUpdate, setOtherTeamToUpdate] = React.useState<?Team>();

  const [searchLoading, setSearchLoading] = React.useState<boolean>(false);

  /**
   * Saves in the right state the change
   * @param {event} event
   */
  const handleChange = (event: any) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    switch (fieldName) {
      case 'searchTeamInput':
        setSearchTeamInput(value);
        break;
      case 'searchTeamSelect':
        setSearchTeamSelect(value);
        break;
      case 'searchUserInput':
        setSearchUserInput(value);
        break;
      case 'searchUserSelect':
        setSearchUserSelect(value);
        break;
      default:
    }
  };

  /**
   * Used to update the state of the team displayed (useful to update the teams linked)
   * @param {string} uid
   * @param {string} name
   * @param {string} administrator
   * @param {string} type
   * @param {string} creatorName
   * @param {string} webCreatorURL
   * @param {User} adminInfo
   */
  const displayUpdatedTeam = (
    uid: string,
    name: string,
    administrator: string,
    type: string,
    adminInfo: User,
    creatorName: string,
    webCreatorURL: string,
  ) => {
    setTeamDisplayed({
      ...teamDisplayed,
      uid,
      name,
      administrator,
      type,
      adminInfo,
      creatorName,
      webCreatorURL,
    });
  };

  return (
    <div className="card bg-light fill screenBlock" style={{ overflow: 'hidden' }}>
      <div className="card-body d-flex p-1 pl-4 mb-2">
        <div className="col-6">
          <UserInfoTeams
            setTeamDisplayed={setTeamDisplayed}
            handleChange={handleChange}
            searchUserInput={searchUserInput}
            searchUserSelect={searchUserSelect}
            setSearchLoading={setSearchLoading}
            searchUserTypes={searchUserTypes}
            teamDisplayed={teamDisplayed}
            otherTeamToUpdate={otherTeamToUpdate}
          />
          <TeamSearch
            setSearchLoading={setSearchLoading}
            searchTeamInput={searchTeamInput}
            searchTeamSelect={searchTeamSelect}
            setTeamDisplayed={setTeamDisplayed}
            handleChange={handleChange}
            searchTeamTypes={searchTeamTypes}
            teamDisplayed={teamDisplayed}
            otherTeamToUpdate={otherTeamToUpdate}
          />
        </div>
        <div className="col-6">
          <EditTeamView
            item={teamDisplayed}
            displayUpdatedTeam={displayUpdatedTeam}
            setTeamDisplayed={setTeamDisplayed}
            setOtherTeamToUpdate={setOtherTeamToUpdate}
          />
        </div>
      </div>
      {searchLoading && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  locale: state.preferences.editionLocale,
});

export default compose(connect(mapStateToProps), withTranslation('default'))(TeamsScreen);
