/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps } from 'storm-react-diagrams';
import { DiscussionServiceHelper } from 'src/store/scenario/items';
import ItemNodeWidget from '../ItemNodeWidget';
import '../nodeStyle.css';
import EntryPointModel from './EntryPointModel';
import { itemTypeColorator } from '../baseItem/BaseItemColorations';

export interface EntryPointNodeProps extends BaseWidgetProps {
  discussionId: string;
  name: string;
  node: EntryPointModel;
}

export interface ItemNodeState {}

class EntryPointWidget extends ItemNodeWidget<EntryPointNodeProps, ItemNodeState> {
  entityRemoved() {
    const { deleteEntryPoint, discussionId, node } = this.props;
    if (deleteEntryPoint) {
      node.removeListener(this);
      deleteEntryPoint(discussionId, node.name);
    }
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: '#e8edf3',
          borderRadius: '50%',
          padding: '10px',
          borderColor: this.props.borderColor,
        }}
      >
        <span className="entryPointName">{this.props.node.name}</span>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussionId, entryPoint } = ownProps.node;
  const color = itemTypeColorator(ownProps.node);

  return {
    entryPoint,
    discussionId,
    color,
  };
};

const mapDispatchToProps = {
  deleteEntryPoint: DiscussionServiceHelper.deleteFirstMessageId,
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryPointWidget);
