/**
 * @flow
 *
 * @format
 */
export const LOCALES = {
  fr: 'fr',
  en: 'en',
  de: 'de',
  es: 'es',
  ro: 'ro',
  ru: 'ru',
};

export const LocaleDefaults = {
  fr: '',
  en: '',
  de: '',
  ru: '',
  es: '',
  ro: '',
};

export type LocaleType = $Values<typeof LOCALES>;

export default class ScenarioLocaleManager {
  defaultLocale: LocaleType;

  availableLocales: LocaleType[];

  constructor() {
    this.restoreDefaults();
  }

  restoreDefaults = () => {
    this.configure(undefined, undefined);
  };

  configure(defaultLocale: ?LocaleType, availableLocales: ?(LocaleType[])) {
    this.defaultLocale = defaultLocale || LOCALES.en;
    this.availableLocales = availableLocales ? [...availableLocales] : [LOCALES.en, LOCALES.fr];
  }

  defaultStringsForLocales = (locales: LocaleType[]) => {
    const res: { [locale: string]: string } = {};
    locales.forEach((locale) => {
      res[locale] = LocaleDefaults[locale] || '';
    });
    return res;
  };

  defaultStringArraysForLocales = (locales: LocaleType[]) => {
    const res: { [locale: string]: string[] } = {};
    locales.forEach((locale) => {
      res[locale] = [];
      res[locale].push(LocaleDefaults[locale] || '');
    });
    return res;
  };

  localizedStringParams = (translate: boolean) => {
    if (!translate) {
      return { defaultLocale: this.defaultLocale, defaultValues: {} };
    }
    return { defaultLocale: this.defaultLocale, defaultValues: this.defaultStringsForLocales(this.availableLocales) };
  };

  localizedStringArrayParams = (translate: boolean) => {
    if (!translate) {
      return {
        defaultLocale: this.defaultLocale,
        defaultValues: {},
      };
    }
    return {
      defaultLocale: this.defaultLocale,
      defaultValues: this.defaultStringArraysForLocales(this.availableLocales),
    };
  };
}

const Singleton = new ScenarioLocaleManager();
export { Singleton };
