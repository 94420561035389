/**
 * @flow
 *
 * @format
 */
import { generateId } from 'src/data/AtlObject';
import * as types from './types';
import type { NotificationType } from './EventsReducer';

export const addNotif = (
  type: NotificationType,
  message: string,
  extra: any,
  timeToStay?: number,
  action?: { title: string, callback: () => Promise<any>, closeOnCallback?: boolean },
) => ({
  type: types.NOTIF_ADD,
  payload: {
    notification: {
      type,
      message,
      date: Math.floor(Date.now() / 1000),
      extra,
      timeToStay,
      action,
      id: generateId(),
    },
  },
});
export const closeNotif = (notifId: string) => ({
  type: types.NOTIF_REMOVE,
  payload: { id: notifId },
});
export const cleanupNotifs = () => ({
  type: types.NOTIF_CLEANUP,
  payload: {},
});
