/**
 * @flow
 * @format
 */

export const ONBOARDING_UPDATE_COORDINATES = 'onboarding/ONBOARDING_UPDATE_COORDINATES';
export const ONBOARDING_RESET_VALUES = 'onboarding/ONBOARDING_RESET_VALUES';
export const ONBOARDING_UPDATE_NAME_PITCH_MISSION = 'onboarding/ONBOARDING_UPDATE_NAME_PITCH_MISSION';
export const ONBOARDING_UPDATE_DEPARTURE = 'onboarding/ONBOARDING_UPDATE_DEPARTURE';
export const ONBOARDING_UPDATE_TEAM_ID = 'onboarding/ONBOARDING_UPDATE_TEAM_ID';
