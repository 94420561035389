/**
 * @flow
 *
 * @format
 */
import { Scenario } from 'src/data';
import { CatalogTypes } from '../types';
import type { CatalogType } from '../types';
import { Singleton as FirebaseSingleton } from '../Firebase';
import { pushEditorAssetAsync, getEditorAssetUrlAsync, getNextVersionAsync } from './common';
import type { pushEditorAssetType, getNextVersionAsyncType, getEditorAssetUrlAsyncType } from './common';

/* *********************
 * Scenario
 */

export const pushScenarioEditorAssetAsync: pushEditorAssetType = async (scenarioId, filename, file: File) => {
  const storageFile = FirebaseSingleton.scenarioEditorStorage(scenarioId);
  return pushEditorAssetAsync(storageFile, filename, file);
};

export const getScenarioEditorAssetUrlAsync: getEditorAssetUrlAsyncType = async (scenarioId, filename) =>
  getEditorAssetUrlAsync(FirebaseSingleton.scenarioEditorStorage(scenarioId), filename);

export const getScenarioNextVersionAsync: getNextVersionAsyncType = (scenarioId) =>
  getNextVersionAsync(FirebaseSingleton.scenarioData(scenarioId));

export const getAllScenariosAsync = async (catalog: CatalogType = CatalogTypes.prod) => {
  const scenariosSnapshot = await FirebaseSingleton.scenariosHeader(catalog).once('value');

  if (scenariosSnapshot.exists()) {
    const scenarios = {};
    scenariosSnapshot.forEach((scenarioSnapshot) => {
      scenarios[scenarioSnapshot.key] = new Scenario(scenarioSnapshot.val());
    });
    return scenarios;
  }

  return {};
};

export const translateString = async (text: string, sourceLocale: string, destLocale: string) => {
  const res = await FirebaseSingleton.translateString(text, sourceLocale, destLocale);

  const translation = res.data[0];
  return translation ? translation.translatedText : '';
};
