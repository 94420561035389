/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import LanguagesDropdown from './LanguagesDropdown';

type LanguagesSettingsProps = {
  availableLanguages: Array<string>,
  currentLanguage: string,
  languageTypeTitle: string,
  explanationSettings: string,
  idDropdown: string,
  changeLanguage: (lng: string) => void,
};

const LanguagesSettings = ({
  availableLanguages,
  currentLanguage,
  languageTypeTitle,
  explanationSettings,
  idDropdown,
  changeLanguage,
}: LanguagesSettingsProps) => {
  return (
    <div className="card-body mb-0">
      <h5>{languageTypeTitle}</h5>
      <div className="row d-flex align-items-center">
        <div className="mr-3 ml-3 mt-1 mb-1 col-5">{explanationSettings}</div>
        <LanguagesDropdown
          availableLanguages={availableLanguages}
          currentLanguage={currentLanguage}
          idDropdown={idDropdown}
          changeLanguage={changeLanguage}
        />
      </div>
    </div>
  );
};

export default compose(withTranslation('default'))(LanguagesSettings);
