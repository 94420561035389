/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import Firebase, { FirebaseSingleton } from 'src/services/Firebase';
import * as Globals from 'src/constants/globals';
import * as actions from './actions';
import type { User } from '../../data/types/UserType';
import type { Team } from '../../data/types/TeamType';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# UserServiceHelper / ${title}`, args);
  }
};

export type loginType = (email: string, password: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const loginAsync: loginType = (email, password, firebase) => async (dispatch) => {
  logHelperCall('loginAsync', { email, password });
  return firebase.doSignInWithEmailAndPassword(email, password).then(() => {
    dispatch(actions.setLastEmail(email));
  });
};

export type signUpAsyncType = (
  email: string,
  password: string,
  tosAgreed: boolean,
  confidentialityAgreed: boolean,
) => (ReduxDispatch) => Promise<void>;
export const signUpAsync: signUpAsyncType = (email, password, tosAgreed, cguAgreed) => async (dispatch) => {
  logHelperCall('signUpAsync', { email, tosAgreed, cguAgreed });
  const res = await FirebaseSingleton.signUpEditor(email, password, tosAgreed, cguAgreed);
  if (res.error) {
    throw new Error(res.error);
  }
  return await loginAsync(email, password, FirebaseSingleton)(dispatch);
};

export type socialLoginAsyncType = (firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const loginWithFacebookAsync: socialLoginAsyncType = (firebase) => async (dispatch) => {
  logHelperCall('loginWithFacebook', {});
  const res = await firebase.doSignInWithFacebook();
  dispatch(actions.setLastEmail(res.email));
  return res;
};
export const loginWithAppleAsync: socialLoginAsyncType = (firebase) => async (dispatch) => {
  logHelperCall('loginWithApple', {});
  const res = await firebase.doSignInWithApple();
  dispatch(actions.setLastEmail(res.email));
  return res;
};
export const loginWithGoogleAsync: socialLoginAsyncType = (firebase) => async (dispatch) => {
  logHelperCall('loginWithGoogle', {});
  const res = await firebase.doSignInWithGoogle();
  dispatch(actions.setLastEmail(res.email));
  return res;
};

export type sendForgotPasswordType = (email: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const sendForgotPassword: sendForgotPasswordType = (email, firebase) => async (dispatch) => {
  logHelperCall('sendForgotPassword', { email });

  try {
    await firebase.sendForgotPasswordEmail(email);
    EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_FORGOT_PASSWORD')(dispatch);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_FORGOT_PASSWORD')(dispatch);
  }
};

export type setUserType = (user: any) => (ReduxDispatch) => Promise<void>;
export const setUser: setUserType = (user) => async (dispatch) => {
  logHelperCall('setUser', { user });
  if (user) {
    let userSnap = await FirebaseSingleton.user(user.uid).once('value');
    if (!userSnap.exists()) {
      await FirebaseSingleton.becomeEditor();
      userSnap = await FirebaseSingleton.user(user.uid).once('value');
    }
    const userData = userSnap.val();
    dispatch(actions.setUser(userData));
  } else {
    dispatch(actions.setUser());
  }
};
export type updateConsentAsyncType = (
  user: any,
  editorTosAgreed: boolean,
  editorConfidentialityAgreed: boolean,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const updateConsentAsync: updateConsentAsyncType = (
  user,
  editorTosAgreed,
  editorConfidentialityAgreed,
  firebase,
) => async (dispatch) => {
  logHelperCall('updateConsentAsync', { editorTosAgreed, editorConfidentialityAgreed });
  const res = await firebase.updateUserConsent(editorTosAgreed, editorConfidentialityAgreed);
  dispatch(actions.updateConsents(res));
  return setUser(user)(dispatch);
};

export type logoutType = (firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const logoutAsync: logoutType = (firebase) => async () => {
  logHelperCall('logout', {});
  await firebase.doSignOut();
};

export type updateTeamAppTestersType = (
  teamId?: string,
  matriculeToAdd?: string,
  uidToRemove?: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<?Team>;
export const updateTeamAppTesters: updateTeamAppTestersType = (teamId, matriculeToAdd, uidToRemove, firebase) => async (
  dispatch,
) => {
  logHelperCall('updateTeamAppTesters', {});
  try {
    const res = await firebase.updateTeamAppTesters(teamId, matriculeToAdd, uidToRemove);
    return res;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return undefined;
  }
};

export type updateTeamStudioEditorsType = (
  teamId?: string,
  emailToAdd?: string,
  uidToRemove?: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<?Team>;
export const updateTeamStudioEditors: updateTeamStudioEditorsType = (
  teamId,
  emailToAdd,
  uidToRemove,
  firebase,
) => async (dispatch) => {
  logHelperCall('updateTeamStudioEditors', {});
  try {
    const res = await firebase.updateTeamStudioEditors(teamId, emailToAdd, uidToRemove);
    return res;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return undefined;
  }
};

export type getUserAdministratedTeamsType = (userId?: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const getUserAdministratedTeams: getUserAdministratedTeamsType = (userId, firebase) => async (dispatch) => {
  logHelperCall('getUserAdministratedTeams', {});
  try {
    const res = await firebase.getUserAdministratedTeams(userId);
    return Object.values(res);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
  }
};

export type getUserEditingTeamsType = (userId?: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const getUserEditingTeams: getUserEditingTeamsType = (userId, firebase) => async (dispatch) => {
  logHelperCall('getUserEditingTeams', {});
  try {
    const res = await firebase.getUserEditingTeams(userId);
    // dispatch(actions.setMatricule(matricule));
    return Object.values(res);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
  }
};

/**
 * Retrieve all the teams of the given user (administrated/editing/testing)
 */
export type getUserAllTeamsType = (userId: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const getUserAllTeams: getUserAllTeamsType = (userId, firebase) => async (dispatch) => {
  logHelperCall('getUserAllTeams', {});
  try {
    const res = await firebase.getUserAllTeams(userId);
    return res;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
  }
};

/**
 * POTENTIALLY DEPRECATED
 * Never used ??
 */
export type bulkCreateEditorUsersType = (emails: string, firebase: Firebase) => (ReduxDispatch) => Promise<void>;
export const bulkCreateEditorUsers: bulkCreateEditorUsersType = (emails, firebase) => async (dispatch) => {
  logHelperCall('bulkCreateEditorUsers', {});
  try {
    const res = await firebase.bulkCreateEditorUsers(emails);
    const { success, successedUsers } = res;
    if (success) {
      EventsServiceHelper.addNotif(NotificationTypes.SUCCESS, 'S_USERS_CREATION_SUCCESS', successedUsers)(dispatch);
    } else {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_USERS_CREATION_FAILED', res)(dispatch);
    }
    return res;
  } catch (error) {
    console.error(error);
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_USERS_CREATION_CRASH', error)(dispatch);
  }
};

export type updateTeamStudioMembersType = (
  emailToAdd: string,
  uidToRemove: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<void>;
export const updateTeamStudioMembers: updateTeamStudioMembersType = (emailToAdd, uidToRemove, firebase) => async () => {
  logHelperCall('updateTeamStudioMembers', {});
  try {
    const res = await firebase.updateTeamStudioMembers(emailToAdd, uidToRemove);
    // dispatch(actions.setMatricule(matricule));
    return res;
  } catch (error) {
    console.error(error);
  }
};

/**
 * Retrieve the user by using one of the following criteria (uid,email or collarNumber)
 */
export type searchUserType = (
  searchString: string,
  typeSearch: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<?User>;
export const searchUser: searchUserType = (searchString, typeSearch, firebase) => async (dispatch) => {
  logHelperCall('searchUser');
  try {
    return await firebase.searchUser(searchString, typeSearch);
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_USER_NOT_FOUND')(dispatch);
    return undefined;
  }
};
