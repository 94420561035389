/**
 * @flow
 * @format
 *
 */

module.exports = {
  // Contains all the languages the editor has, the first one being the default
  availableLanguagesEditor: ['fr', 'en'],
};
