/**
 * @flow
 *
 * @format
 */

import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import type { ReduxDispatch } from 'redux';
import { Singleton as FirebaseSingleton } from '../Firebase';

// Sales
export type getScenarioSalesStatisticsType = (
  scenarioId: string,
  startDate?: string,
  endDate?: string,
  period?: string,
) => (ReduxDispatch) => Promise<any[]>;
export const getScenarioSalesStatistics: getScenarioSalesStatisticsType = (
  scenarioId,
  startDate,
  endDate,
  period,
) => async (dispatch) => {
  try {
    const res = await FirebaseSingleton.getScenarioSalesStatistics(scenarioId, startDate, endDate, period);
    const result = res.data;
    return result;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_STATS_UPDATE_FAILED', error.message)(dispatch);
    return [];
  }
};

export type getCitySalesStatisticsType = (
  cityId: string,
  startDate?: string,
  endDate?: string,
  period?: string,
) => (ReduxDispatch) => Promise<any[]>;
export const getCitySalesStatistics: getCitySalesStatisticsType = (cityId, startDate, endDate, period) => async (
  dispatch,
) => {
  try {
    const res = await FirebaseSingleton.getCitySalesStatistics(cityId, startDate, endDate, period);
    const result = res.data;
    return result;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_STATS_UPDATE_FAILED', error.message)(dispatch);
    return [];
  }
};

export type getScenarioSuccessStatisticsType = (
  scenarioId: string,
  startDate?: string,
  endDate?: string,
  period?: string,
) => (ReduxDispatch) => Promise<any[]>;
export const getScenarioSuccessStatistics: getScenarioSuccessStatisticsType = (
  scenarioId,
  startDate,
  endDate,
  period,
) => async (dispatch) => {
  try {
    const res = await FirebaseSingleton.getScenarioSuccessStatistics(scenarioId, startDate, endDate, period);
    const result = res.data;
    return result;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_STATS_UPDATE_FAILED', error.message)(dispatch);
    return [];
  }
};
