/**
 * @flow
 *
 * @format
 */

export const HEADER_LOAD = 'header/HEADER_LOAD';
export const HEADER_LOAD_CONTENT = 'header/HEADER_LOAD_CONTENT';
export const HEADER_UPDATE = 'header/HEADER_UPDATE';
export const HEADER_UPDATE_LAST_VERSION = 'header/HEADER_UPDATE_LAST_VERSION';
export const HEADER_CLEAN = 'header/HEADER_CLEAN';
