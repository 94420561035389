/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import type { ObjectMap } from './Shortcuts';
import { TriggeredItemConditions } from './TriggeredItem';

/* ***************************
  CLASS
*************************** */

export type AppTimer = {};

export default class TimerItem extends BaseItem {
  duration: number;

  constructor(json: any) {
    if (json instanceof TimerItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, duration, ...newMeta } = json;
    this.type = ItemTypes.Timer;
    this.duration = duration;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.duration = this.duration;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.duration = this.duration;
    return res;
  }

  calculateSelfUnlockedValues(startItemId: string) {
    return [...super.calculateSelfUnlockedValues(startItemId), { state: TriggeredItemConditions.Failed }];
  }

  checkRelease(items: ObjectMap<BaseItem>, locales: string[], startItemId: string) {
    const res = super.checkRelease(items, locales, startItemId);

    if (!this.duration || this.duration <= 0) {
      res.push({
        level: 'warn',
        item: this.id,
        message: 'E_TIMER_NO_DURATION',
        details: { message: 'The timer duration should be set' },
      });
    }
    return res;
  }
}
