/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import * as types from './types';

export type GraphPreferenceType = { x: number, y: number, zoom: number };

export type PreferencesReducerState = {
  lastLat: number,
  lastLong: number,
  lastZoom: number,
  editionLocale: string,
  graphPreferences: { [s: string]: GraphPreferenceType },
  urlProperties: { [key: string]: string },
};

export const PreferencesReducerDefaultState = {
  lastLat: 43.612028554579794,
  lastLong: 3.874582011560733,
  lastZoom: 12,
  editionLocale: 'fr',
  graphPreferences: {},
  urlProperties: {},
};

const reducer = (
  state: PreferencesReducerState = PreferencesReducerDefaultState,
  action: { type: string, payload: any },
) => {
  let newState;
  switch (action.type) {
    case types.URL_PROPERTIES_CHANGED: {
      newState = {
        ...state,
        urlProperties: action.payload.urlProperties,
      };
      break;
    }
    case types.PREFERENCES_SET_LAST_COORDINATE: {
      const { lat, lon, zoom } = action.payload;
      newState = {
        ...state,
        lastLat: lat,
        lastLong: lon,
        lastZoom: zoom || state.lastZoom,
      };
      break;
    }
    case types.PREFERENCES_SET_GRAPH_OFFSET: {
      const { graphId, x, y } = action.payload;
      newState = {
        ...state,
      };
      const oldPref = newState.graphPreferences[graphId] || {};
      const newPref = { ...oldPref };
      newPref.x = x;
      newPref.y = y;
      newState.graphPreferences[graphId] = newPref;
      break;
    }
    case types.PREFERENCES_SET_GRAPH_ZOOM: {
      const { graphId, zoom } = action.payload;
      newState = {
        ...state,
      };
      const oldPref = newState.graphPreferences[graphId] || {};
      const newPref = { ...oldPref };
      newPref.zoom = zoom;
      newState.graphPreferences[graphId] = newPref;
      break;
    }
    case types.PREFERENCES_SET_EDITION_LOCALE: {
      const { locale } = action.payload;
      newState = { ...state };
      newState.editionLocale = locale;
      break;
    }
    default:
      newState = state;
      break;
  }
  return newState;
};

export const PreferencesReducerKey = 'preferences';

const preferencesPersistConfig = {
  key: PreferencesReducerKey,
  storage,
  transforms: [],
};
export default createNamedReducer({
  moduleName: PreferencesReducerKey,
  reducer: persistReducer(preferencesPersistConfig, reducer),
});
