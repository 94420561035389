/**
 * @flow
 * @format
 */

import * as React from 'react';
import { InputString, InputBoolean } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';

type Props = {
  nameScenario: string,
  nameCheckbox: boolean,
  updateNamePitchMissionScenario: OnboardingServiceHelper.updateNamePitchMissionScenarioType,
  t: (key: string) => string,
};

const OnboardingName = ({ nameScenario, nameCheckbox, updateNamePitchMissionScenario, t }: Props) => {
  const handleChange = (event: { target: { value: boolean, id: string } }) => {
    const { value } = event.target;
    const fieldName = event.target.id;
    let newNameScenario = nameScenario;
    let newNameCheckbox = nameCheckbox;
    switch (fieldName) {
      case 'checkboxNameScenario':
        newNameCheckbox = value;
        if (value) {
          newNameScenario = '';
        }
        break;
      case 'name':
        newNameScenario = value;
        break;
      default:
    }
    updateNamePitchMissionScenario(newNameScenario, newNameCheckbox, 'name');
  };

  return (
    <div className="p-4">
      <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
      <InputString
        className="pb-2 pt-2"
        fieldName="name"
        value={nameScenario}
        help={t('screens.headerEdition.identification.nameHelp')}
        disabled={nameCheckbox}
        handleChange={handleChange}
      />
      <div className="mt-2 mb-2 font-weight-bold">
        <InputBoolean
          fieldName="checkboxNameScenario"
          value={nameCheckbox}
          handleChange={handleChange}
          help={t('screens.newScenario.onboarding.nameScreen.helpName')}
          label={t('screens.newScenario.onboarding.nameScreen.labelName')}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  nameScenario: state.scenario.onboarding.nameScenario,
  nameCheckbox: state.scenario.onboarding.nameCheckbox,
});

const mapDispatchToProps = {
  updateNamePitchMissionScenario: OnboardingServiceHelper.updateNamePitchMissionScenario,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(OnboardingName);
