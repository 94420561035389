/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { BaseWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { BaseItem } from 'src/data';
import ItemNodeWidget from '../ItemNodeWidget';

export interface BaseItemWidgetProps extends BaseWidgetProps {
  itemId: string;
  nodeId: string;
  outOfRedux: boolean;
  item: any;
  deleteItem: ItemsServiceHelper.removeItemType;
  diagramEngine: DiagramEngine;
  itemTypeClass: string;
}

export interface ItemNodeState {}

class BaseItemWidget extends ItemNodeWidget<BaseItemWidgetProps, ItemNodeState> {
  state = {};

  entityRemoved() {
    const { deleteItem, scenarioId, item, nodeId } = this.props;
    if (deleteItem) {
      deleteItem(scenarioId, item, nodeId);
    }
  }

  parentChanged() {
    this.forceUpdate();
  }

  reachableChanged() {
    this.forceUpdate();
  }

  render() {
    return (
      <div
        {...this.getProps()}
        style={{
          background: this.props.color,
          borderColor: this.props.node.borderColor,
          top: this.props.node.y,
          left: this.props.node.x,
        }}
      >
        <div className={`${this.bem('__title')} ${this.props.itemTypeClass}`}>
          <div className={this.bem('__name')}>{this.props.title}</div>
        </div>
        <div className={this.bem('__ports')}>
          <div className={this.bem('__in')}>{_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}</div>
          <div className={this.bem('__out')}>{_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps: BaseItemWidgetProps) => {
  const { node, colorator } = ownProps;
  let { color } = node;
  const { item } = node;
  const { id } = item;
  const nodeId = node.item && node.item.nodeId;
  let itemRedux: ?BaseItem;
  let itemCheckRedux: any;
  if (!id) {
    itemRedux = state.scenario.items.__detachedNodes.items.find((it) => it.nodeId === nodeId);
    if (!itemRedux) {
      // $FlowFixMe Object.values
      itemRedux = Object.values(state.scenario.items).find((it) => it.nodeId === nodeId);
    }
    if (itemRedux && id !== itemRedux.id) {
      // eslint-disable-next-line no-param-reassign
      ownProps.node.item.id = itemRedux.id;
    }
  } else {
    itemRedux = state.scenario.items[id];
    itemCheckRedux = state.scenario.validity[id];
  }

  color = colorator(item, itemCheckRedux);
  const locale = state.preferences.editionLocale;
  const content = itemRedux && itemRedux.getTitle(locale);
  return {
    scenarioId: state.scenario.header.id,
    nodeId,
    color,
    itemId: id,
    item: itemRedux,
    title: content,
    itemTypeClass: itemRedux && itemRedux.getGraphTitleClass(),
  };
};

const mapDispatchToProps = {
  deleteItem: ItemsServiceHelper.removeItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseItemWidget);
