/**
 * @flow
 * @format
 */

import type { ReduxDispatch } from 'redux';
import * as actions from './actions';

/**
 * Updates the coordinates of the start POI, when doing onboarding
 */
export type UpdateCoordinatesType = (latitude: number, longitude: number) => (ReduxDispatch) => Promise<void>;
export const updateCoordinates: UpdateCoordinatesType = (latitude: number, longitude: number) => async (dispatch) => {
  dispatch(actions.setMarkerStart({ latitude, longitude }));
};

/**
 * Reset the onboarding values to default
 */
export type ResetOnboardingValuesType = () => (ReduxDispatch) => Promise<void>;
export const resetOnboardingValues: ResetOnboardingValuesType = () => async (dispatch) => {
  dispatch(actions.resetOnboardingValues());
};

/**
 * Updates the name/pitch or mission of the scenario, and the state of the checkbox
 */
export type updateNamePitchMissionScenarioType = (
  valueString: string,
  valueCheckbox: boolean,
  fieldName: string,
) => (ReduxDispatch) => Promise<void>;
export const updateNamePitchMissionScenario: updateNamePitchMissionScenarioType = (
  valueString: string,
  valueCheckbox: boolean,
  fieldName: string,
) => async (dispatch) => {
  dispatch(actions.updateNamePitchMissionScenario(valueString, valueCheckbox, fieldName));
};

/**
 * Updates the value for the year and the subtitles in fr/en
 */
export type UpdateDepartureScenarioType = (departure: string) => (ReduxDispatch) => Promise<void>;
export const updateDepartureScenario: UpdateDepartureScenarioType = (departure: string) => async (dispatch) => {
  dispatch(actions.updateDepartureScenario(departure));
};

/**
 * Updates the value for the team id input
 */
export type UpdateTeamIdType = (teamInputId: string) => (ReduxDispatch) => Promise<void>;
export const updateTeamId: UpdateTeamIdType = (teamInputId: string) => async (dispatch) => {
  dispatch(actions.updateTeamId(teamInputId));
};
