/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { DiamondNodeWidgetProps, DiagramEngine } from 'storm-react-diagrams';
import { BaseItem } from 'src/data';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import type { AtlEditorState } from 'src/store';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ItemNodeWidget from '../ItemNodeWidget';
import SVGNode from './SVGNode';

export interface BaseItemWidgetProps extends DiamondNodeWidgetProps {
  itemId: string;
  nodeId: string;
  outOfRedux: boolean;
  item: any;
  deleteTrigger: ItemsServiceHelper.updateTriggeredItemType;
  diagramEngine: DiagramEngine;
  itemTypeClass: string;
  triggerInLabel: string;
  triggerOutLabel: string;
}

export interface ItemNodeState {
  hadNodeId?: string;
}

class TriggeredItemNodeWidget extends ItemNodeWidget<BaseItemWidgetProps, ItemNodeState> {
  static defaultProps = {
    size: 50,
  };

  state = {
    hadNodeId: undefined,
    borderColorSelected: false,
  };

  componentDidUpdate = (oldProps: TriggeredItemNodeWidget, oldState: ItemNodeState) => {
    const { triggerNodeId } = this.props;
    const { triggerNodeId: oldTrigger } = oldProps;
    if (triggerNodeId) {
      if (triggerNodeId !== oldTrigger || !this.state.hadNodeId) {
        this.setState({ hadNodeId: triggerNodeId });
      }
    } else {
      const { hadNodeId } = this.state;
      if (hadNodeId && !triggerNodeId) {
        this.setState({ hadNodeId: null });
      }
      const { hadNodeId: oldHad } = oldState;
      if (hadNodeId !== oldHad) {
        const { diagramEngine } = this.props;
        diagramEngine.diagramModel.removeNode(oldHad);
        const { links } = diagramEngine.getDiagramModel();
        Object.keys(links).forEach((linkId) => {
          const link = links[linkId];
          const source = link.sourcePort && link.sourcePort.parent;
          const target = link.targetPort && link.targetPort.parent;
          if ((source && source.nodeId === triggerNodeId) || (target && target.nodeId === triggerNodeId)) {
            diagramEngine.diagramModel.removeLink(linkId);
          }
        });
      }
    }
  };

  entityRemoved() {
    const { deleteTrigger, sourceItemId, targetItemId, triggerNodeId } = this.props;
    if (deleteTrigger) {
      deleteTrigger(sourceItemId, targetItemId, triggerNodeId);
    }
  }

  parentChanged() {
    this.forceUpdate();
  }

  reachableChanged() {
    this.forceUpdate();
  }

  // eslint-disable-next-line class-methods-use-this
  selectionChanged() {
    this.setState({ borderColorSelected: !this.state.borderColorSelected });
  }

  render() {
    return (
      <>
        <div
          style={{
            textAlign: 'center',
            borderRadius: '10px',
            padding: '2px',
            maxWidth: `${this.props.size * 2}px}`,
            backgroundColor: '#E8EBED',
            fontSize: '14px',
            marginBottom: '-7px',
          }}
        >
          {this.props.triggerInLabel}
        </div>
        <div>
          <div
            className={'diamond-node justify-content-center'}
            style={{
              position: 'relative',
              width: this.props.size,
              height: this.props.size,
              left: this.props.size / 2,
            }}
          >
            <div className={this.bem('__ports')}>
              <div
                className={this.bem('__in')}
                style={{
                  top: 0,
                  right: 0,
                  position: 'absolute',
                }}
              >
                <div
                  style={{
                    top: this.props.size / 2 - 7.5,
                    right: this.props.size / 2 - 7.5,
                    position: 'absolute',
                    zIndex: 0,
                  }}
                >
                  {_.map(this.props.node.getInPorts(), this.generatePort.bind(this))}
                </div>
              </div>
              <div
                className={this.bem('__out')}
                style={{
                  top: 0,
                  right: 0,
                  position: 'absolute',
                }}
              >
                <SVGNode
                  size={this.props.size}
                  fillColor={this.props.color}
                  borderColor={this.state.borderColorSelected && '#01bfff'}
                />
                <div
                  style={{
                    top: this.props.size / 2 - 7.5,
                    right: this.props.size / 2 - 7.5,
                    position: 'absolute',
                    zIndex: 0,
                  }}
                >
                  {_.map(this.props.node.getOutPorts(), this.generatePort.bind(this))}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              textAlign: 'center',
              borderRadius: 10,
              padding: 2,
              width: this.props.size * 2,
              backgroundColor: '#E8EBED',
              fontSize: '14px',
              marginTop: '-7px',
            }}
          >
            {this.props.triggerOutLabel}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AtlEditorState, ownProps: BaseItemWidgetProps) => {
  const { node } = ownProps;
  const { item, parent } = node;
  const { id } = parent;
  const parentNodeId = node.parent && node.parent.nodeId;
  let parentRedux: ?BaseItem;
  if (!id) {
    parentRedux = state.scenario.items.__detachedNodes.items.find((it) => it.nodeId === parentNodeId);
    if (!parentRedux) {
      // $FlowFixMe : Type of object values result
      parentRedux = Object.values(state.scenario.items).find((it) => it.nodeId === parentNodeId);
    }
    if (parentRedux && id !== parentRedux.id) {
      parent.id = parentRedux.id;
    }
  } else {
    parentRedux = state.scenario.items[id];
  }

  let itemRedux;
  if (parentRedux && parentRedux instanceof BaseItem) {
    itemRedux = parentRedux.triggeredItems.find((it) => it.nodeId === item.nodeId);
  }

  let triggerInLabel = '';
  let triggerOutLabel = '';

  if (itemRedux) {
    if (itemRedux.condition) {
      triggerInLabel = ownProps.t(`triggers.${itemRedux.condition.toLowerCase()}`);
    } else {
      triggerInLabel = '-';
    }
    triggerOutLabel =
      ownProps.t(`triggers.${itemRedux.newTriggeredCondition.toLowerCase()}`) || `(${ownProps.t('triggers.added')})`;
    node.getInPorts()[0].label = '';
    node.getOutPorts()[0].label = '';
  }

  return {
    nodeId: parent.nodeId,
    color: '#f4e8f3',
    sourceItemId: parentRedux && parentRedux.id,
    targetItemId: itemRedux && itemRedux.id,
    triggerNodeId: itemRedux && itemRedux.nodeId,
    item: itemRedux,
    title: 'Trigger',
    triggerInLabel,
    triggerOutLabel,
  };
};

const mapDispatchToProps = {
  deleteItem: ItemsServiceHelper.removeItem,
};

export default compose(
  withTranslation('default'),
  connect(mapStateToProps, mapDispatchToProps),
)(TriggeredItemNodeWidget);
