/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';
import type { Team } from 'src/data/types/TeamType';
import * as Globals from 'src/constants/globals';
import { EventsServiceHelper, NotificationTypes } from 'src/store/events';
import Firebase from 'src/services/Firebase';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# TeamServiceHelper / ${title}`, args);
  }
};

export type getTeamDataType = (
  searchString?: string,
  typeSearch: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<Array<Team>>;
export const getTeamData: getTeamDataType = (searchString, typeSearch, firebase) => async (dispatch) => {
  logHelperCall('getTeamData');
  try {
    const teams = await firebase.getTeamData(searchString, typeSearch);
    if (teams.length === 0) {
      EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_TEAM_NOT_FOUND')(dispatch);
    }
    return teams;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, 'E_TEAM_NOT_FOUND')(dispatch);
    return [];
  }
};

export type createTeamType = (
  name?: string,
  administratorId?: string,
  type: string,
  creatorName?: string,
  webCreatorURL?: string,
  firebase: Firebase,
) => (ReduxDispatch) => Promise<?Team>;
export const createTeam: createTeamType = (name, administratorId, type, creatorName, webCreatorURL, firebase) => async (
  dispatch,
) => {
  logHelperCall('createTeam');
  try {
    const teamCreated = await firebase.createTeamAsync(name, administratorId, type, creatorName, webCreatorURL);
    return teamCreated;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return undefined;
  }
};

export type updateTeamType = (
  id: string,
  name?: string,
  administratorId?: string,
  type?: string,
  firebase: Firebase,
  newTeamId?: string,
  scenarioId?: string,
  creatorName?: string,
  webCreatorURL?: string,
) => (ReduxDispatch) => Promise<?Team>;
export const updateTeam: updateTeamType = (
  id,
  name,
  administratorId,
  type,
  firebase,
  newTeamId,
  scenarioId,
  creatorName,
  webCreatorURL,
) => async (dispatch) => {
  logHelperCall('updateTeam');
  try {
    const teamUpdated = await firebase.updateTeamAsync(
      id,
      name,
      administratorId,
      type,
      newTeamId,
      scenarioId,
      creatorName,
      webCreatorURL,
    );
    return teamUpdated;
  } catch (error) {
    EventsServiceHelper.addNotif(NotificationTypes.ERROR, error.message)(dispatch);
    return undefined;
  }
};

/**
 * Do not display the name if it's the id of the administrator, instead is says "My team"
 */
export const teamNameMyTeam = (team: Team, uidUser: string, t: (key: string) => string) => {
  if (team.name === team.administrator && team.administrator === uidUser) {
    return t('screens.admin.teams.myTeam');
  }
  return team.name;
};
