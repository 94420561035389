/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedFile from './LocalizedFile';

export default class SoundEffectItem extends BaseItem {
  soundFile: ?LocalizedFile;

  volume: number;

  constructor(json: any) {
    if (json instanceof SoundEffectItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { soundFile, volume } = json;
    this.type = ItemTypes.SoundEffect;
    this.soundFile = new LocalizedFile(this.id, 'soundFile', soundFile);
    this.volume = volume !== undefined ? volume : 100;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    if (this.soundFile && this.soundFile.hasFile()) {
      res.soundFile = this.soundFile.serializeForApp();
    }
    res.volume = this.volume;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.soundFile = this.soundFile && this.soundFile.serialize();
    res.volume = this.volume;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();

    if (this.soundFile.hasFile()) {
      res.soundFile = this.soundFile;
    }
    return res;
  }
}
