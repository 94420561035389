/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';
import { DiagramEngine } from 'storm-react-diagrams';
import { toast } from 'react-toastify';
import { i18n } from 'src/assets/locales';
import { Message } from 'src/data';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';
import ItemLinkModel from '../ItemLinkModel';

/* eslint-disable no-use-before-define */
export interface MessageNodeModelListener extends ItemNodeModelListener<MessageNodeModel> {}
/* eslint-enable no-use-before-define */

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

export default class MessageNodeModel extends ItemNodeModel<MessageNodeModelListener> {
  constructor(reachable: boolean = false, message: Message, discussionId: string, messageId: string) {
    super(ItemNodeTypes.Message, (message && messageId) || generateId(), reachable);
    this.message = message;
    this.discussionId = discussionId;
  }

  updateMessage(message: Message) {
    this.message = message;
  }

  getModelId() {
    return this.message.id || this.message.nodeId;
  }

  getId() {
    return this.message.id || this.message.nodeId;
  }

  // eslint-disable-next-line no-unused-vars
  acceptInLinks(outNode: any, port: ItemPortModel, isBeforAdd: boolean) {
    if (!this.message.id) {
      toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkNoId'));
      return false;
    }
    const outType = outNode.type;
    switch (outType) {
      case ItemNodeTypes.Message:
      case ItemNodeTypes.Answer:
      case ItemNodeTypes.AnswerOpened:
      case ItemNodeTypes.EntryPoint:
        return true;
      default:
        toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkType'));
        return false;
    }
  }

  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    // Only identified nodes can be linked
    if (!this.message.id) {
      toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkNoId'));
      return false;
    }

    const nextItems = Object.values(port.links);
    const nextMessages = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const { parent } = inPort;
        return parent.type === ItemNodeTypes.Message;
      }
      return false;
    });
    const nextAnswers = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const { parent } = inPort;
        return parent.type === ItemNodeTypes.Answer;
      }
      return false;
    });
    const nextAnswersOpened = nextItems.filter((link) => {
      if (link instanceof ItemLinkModel) {
        const inPort = link.getInPort();
        if (!inPort) {
          return false;
        }
        const { parent } = inPort;
        return parent.type === ItemNodeTypes.AnswerOpened;
      }
      return false;
    });
    const maxNextMessages = isBeforeAdd ? 1 : 2; // In case the link is already added, it counts!

    // Filter accepted types
    const inType = inNode.type;
    switch (inType) {
      case ItemNodeTypes.Message:
        if (nextAnswers.length !== 0) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkAnswers'));
        }
        if (nextAnswersOpened.length !== 0) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkAnswersOpened'));
        }
        if (nextMessages.length >= maxNextMessages) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkMessageCount'));
        }
        return nextAnswers.length === 0 && nextAnswersOpened.length === 0 && nextMessages.length < maxNextMessages;
      case ItemNodeTypes.Answer:
        if (nextAnswersOpened.length) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkAnswersOpened'));
        }
        if (nextMessages.length > 0) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkMessageCount'));
        }
        return nextMessages.length === 0 && nextAnswersOpened.length === 0;
      case ItemNodeTypes.AnswerOpened:
        if (nextAnswers.length) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkAnswers'));
        }
        if (nextMessages.length > 0) {
          toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkMessageCount'));
        }
        return nextMessages.length === 0 && nextAnswers.length === 0;
      case ItemNodeTypes.Trigger:
        return true;
      default:
        toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkUnknown'));
        return false;
    }
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In'));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out'));
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
