/**
 * @flow
 *
 * @format
 */

import * as _ from 'lodash';
import { DiagramEngine, BaseEvent } from 'storm-react-diagrams';
import { toast } from 'react-toastify';
import { i18n } from 'src/assets/locales';

import { AnswerOpened } from 'src/data';
import { ItemNodeModel, ItemNodeTypes } from '../ItemNodeModel';
import ItemLinkModel from '../ItemLinkModel';
import ItemPortModel from '../ItemPortModel';
import type { ItemNodeModelListener } from '../ItemNodeModel';
import AnswerNodeModel from './AnswerNodeModel';

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

/* eslint-disable no-use-before-define */
export interface AnswerOpenedNodeModelListener extends ItemNodeModelListener<AnswerOpenedNodeModel> {
  parentChanged?: (event: BaseEvent<AnswerNodeModel> & { nodeId: null | string, modelId: null | string }) => void;
}
/* eslint-enable no-use-before-define */

export default class AnswerOpenedNodeModel extends ItemNodeModel<AnswerOpenedNodeModelListener> {
  constructor(reachable: boolean = false, answer: AnswerOpened, discussionId: string) {
    super(ItemNodeTypes.AnswerOpened, answer.nodeId || generateId(), reachable);
    this.item = answer;
    this.discussionId = discussionId;
  }

  getModelId() {
    return this.item.id;
  }

  initPorts() {
    this.addPort(new ItemPortModel(true, `${this.id}_in`, 'In', undefined, 1));
    this.addPort(new ItemPortModel(false, `${this.id}_out`, 'Out', undefined, 1));
  }

  acceptInLinks(outNode: any) {
    if (!this.item.nodeId) {
      return false;
    }
    return outNode.type === ItemNodeTypes.Message;
  }

  inLinkAdded(nodeId: string, modelId: string) {
    this.messageId = modelId;
    const answer = new AnswerOpened(this.item);
    answer.parentMessageId = modelId;
    this.item = answer;
    this.iterateListeners((listener: AnswerOpenedNodeModelListener, event: BaseEvent) => {
      if (listener.parentChanged) {
        listener.parentChanged({ ...event, nodeId, modelId });
      }
    });
  }

  acceptOutLinks(inNode: any, port: any, isBeforeAdd: boolean) {
    // Only identified nodes can be linked
    if (!this.item.id) {
      toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkNoId'));
      return false;
    }

    if (this.item && this.item.hasNextCustom()) {
      toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkNextCustom'));
      return false;
    }

    // Maximum 1 output per answer
    let links = Object.values(port.links);
    links = links.filter((link) => link && link instanceof ItemLinkModel && link.targetPort !== null);
    if (isBeforeAdd) {
      if (links.length >= 1) {
        toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkTooManyLinks'));
        return false;
      }
    } else if (links.length >= 2) {
      toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkTooManyLinks'));
      return false;
    }

    // Filter accepted types
    const inType = inNode.type;
    switch (inType) {
      case ItemNodeTypes.Message:
        return true;
      default:
        toast.error(i18n.t('default:screens.discussionEdition.graph.cantLinkUnknown'));
        return false;
    }
  }

  updateAnswer(answer: AnswerOpened) {
    this.item = answer;
  }

  deSerialize(object: any, engine: DiagramEngine) {
    super.deSerialize(object, engine);
  }

  serialize() {
    return _.merge(super.serialize(), {});
  }
}
