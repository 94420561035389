/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
import * as types from './types';

export const NotificationTypes = {
  DEBUG: 'DEBUG',
  SUCCESS: 'SUCCESS',
  WARN: 'WARN',
  ERROR: 'ERROR',
};
export type NotificationType = $Values<typeof NotificationTypes>;

export type Notification = {
  type: NotificationType,
  id?: string,
  date?: number,
  message: string,
  extra: any,
  action?: { title: string, callback: () => Promise<any> },
  timeToStay?: number,
};

export type EventsReducerState = { notifications: Notification[] };
export const EventsReducerDefaultState = {
  notifications: [],
};

const reducer = (state: EventsReducerState = EventsReducerDefaultState, action: { type: string, payload: any }) => {
  let newState: EventsReducerState;
  switch (action.type) {
    case types.NOTIF_ADD: {
      const { notification } = action.payload;
      newState = { ...state };
      newState.notifications = [...state.notifications];
      newState.notifications.push(notification);
      break;
    }
    case types.NOTIF_REMOVE: {
      const { id } = action.payload;
      newState = { ...state };
      newState.notifications = state.notifications.filter((it) => it.id !== id);
      break;
    }
    case types.NOTIF_CLEANUP:
      newState = { ...state };
      newState.notifications = [];
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const EventsReducerKey = 'events';

export default createNamedReducer({
  moduleName: EventsReducerKey,
  reducer,
});
