/** @format */

export default {
  message_answer_default_missing: {
    key: 'message_answer_default_missing',
    text: 'screens.discussionEdition.errorMessages.messageAnswerDefaultMissing',
    class: 'alert-danger',
  },
  message_answer_default_duplicated: {
    key: 'answer_default_duplicated',
    text: 'screens.discussionEdition.errorMessages.messageAnswerDefaultDuplicated',
    class: 'alert-danger',
  },
  message_answer_default_order: {
    key: 'messagee_answer_default_order',
    text: 'screens.discussionEdition.errorMessages.messageAnswerDefaultOrder',
    class: 'alert-danger',
  },
  answer_default_order: {
    key: 'answer_default_order',
    text: 'screens.discussionEdition.errorMessages.messageAnswerDefaultOrder',
    class: 'alert-danger',
  },
  node_unreachable: {
    key: 'node_unreachable',
    text: 'screens.discussionEdition.errorMessages.nodeUnreachable',
    class: 'alert-warning',
  },
  item_not_saved: {
    key: 'item_not_saved',
    text: 'screens.discussionEdition.errorMessages.itemNotSaved',
    class: 'alert-info',
  },
  id_missing: {
    key: 'id_missing',
    text: 'screens.discussionEdition.errorMessages.idMissing',
    class: 'alert-warning',
  },
  answer_with_wrong_format: {
    key: 'answer_with_wrong_format',
    text: 'screens.discussionEdition.errorMessages.answerWithWrongFormat',
    class: 'alert-danger',
  },
};
