/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import Firebase, { withFirebase } from 'src/services/Firebase';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';
import type { Team } from 'src/data/types/TeamType';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputString } from 'src/pages/components';

export type Props = {
  team?: Team,
  firebase: Firebase,
  includesMembers?: boolean,
  creatorName: string,
  webCreatorURL: string,
  setCreatorName: (key: string) => void,
  setWebCreatorURL: (key: string) => void,
  setLoading?: (loading: boolean) => void,
  setTeam?: (team: Team) => void,
  updateTeam: TeamServiceHelper.updateTeamType,
  t: (key: string) => string,
};

const EditTeamCreatorInfo = ({
  firebase,
  team,
  includesMembers,
  creatorName,
  webCreatorURL,
  setCreatorName,
  setWebCreatorURL,
  updateTeam,
  setTeam,
  setLoading,
  t,
}: Props) => {
  const handleCreatorChange = (event) => {
    setCreatorName(event.target.value);
  };

  const handleWebCreatorURL = (event) => {
    setWebCreatorURL(event.target.value);
  };

  /**
   * Update the current team displayed, or creates a new one with the given info
   */
  const updateTeamCreators = async (event) => {
    if (setLoading) setLoading(true);
    const creatorNameToSend = event.target.id.includes('creatorName') ? creatorName : undefined;
    const webCreatorURLToSend = event.target.id.includes('webCreatorURL') ? webCreatorURL : undefined;
    if (team) {
      const updatedTeam: Team = await updateTeam(
        team.uid,
        team.name,
        team.administrator,
        team.type,
        firebase,
        undefined,
        undefined,
        creatorNameToSend,
        webCreatorURLToSend,
      );
      if (setTeam) setTeam(updatedTeam);
    }
    if (setLoading) setLoading(false);
  };

  return (
    <>
      <div className="mt-2">
        <InputString
          inlineTitle={!includesMembers}
          fieldName="creatorName"
          value={creatorName}
          label={t('screens.admin.teams.creatorNameLabel')}
          handleChange={handleCreatorChange}
          suffix={
            !includesMembers && (
              <button className={'btn mb-0 '} type="button" id="button-creatorName" onClick={updateTeamCreators}>
                {t('general.save')}
              </button>
            )
          }
        />
      </div>
      <div className="mt-2">
        <InputString
          inlineTitle={!includesMembers}
          fieldName="webCreatorURL"
          value={webCreatorURL}
          label={t('screens.admin.teams.webCreatorURLLabel')}
          handleChange={handleWebCreatorURL}
          suffix={
            !includesMembers && (
              <button className={'btn mb-0 '} type="button" id="button-webCreatorURL" onClick={updateTeamCreators}>
                {t('general.save')}
              </button>
            )
          }
        />
      </div>
    </>
  );
};

const mapDispatchToProps = {
  updateTeam: TeamServiceHelper.updateTeam,
};

export default compose(
  withFirebase,
  connect(() => {}, mapDispatchToProps),
  withTranslation('default'),
)(EditTeamCreatorInfo);
