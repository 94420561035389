/**
 * @flow
 *
 * @format
 */
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import reduxLogger from 'redux-logger';
/* eslint-enable no-unused-vars */
/* eslint-enable import/no-extraneous-dependencies */
import { persistStore } from 'redux-persist';
import { namedReducerEnhancer } from 'redux-named-reducers';
import * as Globals from 'src/constants/globals';
import { scenarioFirebaseMidleware } from './scenario';
import { roomFirebaseMidleware } from './room';
import rootReducer, { RootReducerDefaultState } from './RootReducer';

export default () => {
  /** ****************
   * MIDDLEWARES
   ***************** */

  // const middlewares = [];
  const middlewares = [reduxThunk, scenarioFirebaseMidleware, roomFirebaseMidleware];
  if (Globals.__DEV__) {
    middlewares.push(reduxLogger);
  }
  const enhancer = compose(namedReducerEnhancer(rootReducer), applyMiddleware(...middlewares));

  /** *****************
   * STORE & PERSISTOR
   ***************** */

  const store = createStore(rootReducer, RootReducerDefaultState, enhancer);
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};
