/**
 * @flow
 *
 * @format
 */

export const NPC_CREATE = 'npc/NPC_CREATE';
export const NPC_UPDATE = 'npc/NPC_UPDATE';
export const NPC_REMOVE = 'npc/NPC_REMOVE';
export const NPC_CLEANUP = 'npc/NPC_CLEANUP';
