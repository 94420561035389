/**
 * @flow
 *
 * @format
 */

export const PREFERENCES_SET_LAST_COORDINATE = 'preferences/PREFERENCES_SET_LAST_COORDINATE';
export const PREFERENCES_SET_GRAPH_OFFSET = 'preferences/PREFERENCES_SET_GRAPH_OFFSET';
export const PREFERENCES_SET_GRAPH_ZOOM = 'preferences/PREFERENCES_SET_GRAPH_ZOOM';
export const PREFERENCES_SET_EDITION_LOCALE = 'preferences/PREFERENCES_SET_EDITION_LOCALE';
export const URL_PROPERTIES_CHANGED = 'preferences/URL_PROPERTIES_CHANGED';
