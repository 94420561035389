/**
 * @flow
 *
 * @format
 */
import type { ReduxDispatch } from 'redux';
import * as Globals from 'src/constants/globals';
import * as actions from './actions';

const logHelperCall = (title, args) => {
  if (Globals.__DEV__) {
    console.log(`################# PreferencesServiceHelper / ${title}`, args);
  }
};

export type dispatchSetRoomType = (roomId?: string) => (ReduxDispatch) => void;
export const dispatchSetRoom: dispatchSetRoomType = (roomId) => (dispatch) => {
  logHelperCall('dispatchSetRoom', { roomId });
  dispatch(actions.setSelectedRoom(roomId));
};

export type dispatchSetRoomItemType = (itemId?: string, nodeId?: string) => (ReduxDispatch) => void;
export const dispatchSetRoomItem: dispatchSetRoomItemType = (itemId, nodeId) => (dispatch) => {
  logHelperCall('dispatchSetRoomItem', { itemId, nodeId });
  dispatch(actions.setSelectedItem(itemId, nodeId));
};

export type updateGraphOffsetType = (x: number, y: number) => (ReduxDispatch) => void;
export const updateGraphOffset: updateGraphOffsetType = (x, y) => (dispatch) => {
  logHelperCall('updateGraphOffset', {
    x,
    y,
  });
  dispatch(actions.updateGraphOffset(x, y));
};

export type updateGraphZoomType = (zoom: number) => (ReduxDispatch) => void;
export const updateGraphZoom: updateGraphZoomType = (zoom) => (dispatch) => {
  logHelperCall('updateGraphZoom', { zoom });
  dispatch(actions.updateGraphZoom(zoom));
};
