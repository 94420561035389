/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { DiagramEngine, DiagramModel, DiagramWidget, MoveItemsAction } from 'storm-react-diagrams';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'storm-react-diagrams/dist/style.min.css';
import { BaseItem } from 'src/data';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  DiscussionNodeFactory,
  ItemNodeModel,
  ItemLinkModel,
  ItemLinkFactory,
  ItemPortFactory,
  ItemNodeTypes,
} from '.';

export type GraphViewProps = {
  canDelete: boolean,
  forcedItemToFocus?: ItemNodeModel<any>,
  draggableWidgets: React.Node[],
  hasDetachedNodes: boolean,
  preferences: { x: number, y: number, zoom: number },
  isRouteReachable: (route: ItemLinkModel, usedRouteIds: string[], reachedNodes: ItemNodeModel<any>[]) => boolean,
  idForRoute: (ItemLinkModel) => string,
  zoomUpdated: (any) => any,
  offsetUpdated: (any) => any,
  nodeSelected: (ItemNodeModel<any>) => any,
  nodeRemoved: (ItemNodeModel<any>) => any,
  linkSelected: (ItemLinkModel) => any,
  createDraggedNodeData: (draggedData: any, pos: any) => BaseItem,
  createNodeModelFromItem: (BaseItem) => ItemNodeModel<any>,
  addNodeToRedux: (item: BaseItem, node?: ItemNodeModel<any>) => any,
  itemNodeMoved: (item: ItemNodeModel<any>, pos: { x: number, y: number }) => any,
  loadGraphNodes: () => { [s: string]: ItemNodeModel<any> },
  engine: DiagramEngine,
  setEngine: (engine: DiagramEngine) => void,
  loadGraphLinks: (
    { [s: string]: ItemNodeModel<any> },
    model: DiagramModel,
  ) => { links: ItemLinkModel[], linksData: any },
  addItemLink: (
    source: ItemNodeModel<any>,
    target: ItemNodeModel<any>,
    link: ItemLinkModel,
    model: DiagramModel,
  ) => any,
  // eslint-disable-next-line no-use-before-define
  onRef: (self: ?GraphView) => any,
  t: (key: string) => string,
};

type State = {
  links: ItemLinkModel[],
  linksData: { in: string, out: string, link: ItemLinkModel }[],
};

class GraphView extends React.Component<GraphViewProps, State> {
  static defaultProps = {};

  state = {
    links: [],
    linksData: [],
    deleteKeys: [46],
  };

  modelUpdated: any;

  linkListener: any;

  itemListener: any;

  constructor(props) {
    super(props);
    this.modelUpdated = {
      linksUpdated: (evt) => {
        const { link } = evt;
        const { links } = this.state;
        if (!links.includes(link)) {
          links.push(link);
          link.addListener(this.linkListener);
          this.updateLink(link);
        }
        this.checkUnreachableNodes();
      },
      offsetUpdated: (evt) => {
        const { offsetUpdated } = this.props;
        if (offsetUpdated) {
          offsetUpdated(evt);
        }
      },
      zoomUpdated: (evt) => {
        const { zoomUpdated } = this.props;
        if (zoomUpdated) {
          zoomUpdated(evt);
        }
      },
    };
    this.linkListener = {
      selectionChanged: (evt) => {
        const node = evt.entity;
        const { linkSelected } = this.props;
        if (linkSelected && evt.isSelected) {
          linkSelected(node);
        }
      },
      sourcePortChanged: (evt) => {
        this.updateLink(evt.entity);
        this.checkUnreachableNodes();
      },
      targetPortChanged: (evt) => {
        this.updateLink(evt.entity);
        this.checkUnreachableNodes();
      },
      entityRemoved: () => {
        this.checkUnreachableNodes();
      },
    };
    this.itemListener = {
      selectionChanged: (evt) => {
        const node = evt.entity;
        const { nodeSelected } = this.props;
        if (nodeSelected && evt.isSelected) {
          nodeSelected(node);
        }
      },
      entityRemoved: (evt) => {
        const node = evt.entity;
        const { nodeRemoved } = this.props;
        if (nodeRemoved) {
          nodeRemoved(node);
        }
        if (node.type !== 'TriggeredItem') {
          // We manually remove the nodes and links from/to the triggers previously linked to the item to avoid issues with links
          const model = this.props.engine.getDiagramModel();
          node.getLinksFromNode(true).forEach((link) => {
            if (link.sourcePort.parent.type === 'TriggeredItem') {
              link.sourcePort.parent.getLinksFromNode(true).forEach((linkTrigger) => model.removeLink(linkTrigger));
              model.removeNode(link.sourcePort.parent);
            }
          });
          node.getLinksFromNode(false).forEach((link) => {
            if (link.targetPort.parent.type === 'TriggeredItem') {
              link.targetPort.parent.getLinksFromNode(false).forEach((linkTrigger) => model.removeLink(linkTrigger));
              model.removeNode(link.targetPort.parent);
            }
          });
        }
        this.checkUnreachableNodes();
      },
    };
    this.updateDeleteKeys();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.reload();
  }

  componentDidUpdate = (oldProps: GraphViewProps) => {
    if (this.props.canDelete !== oldProps.canDelete) {
      this.updateDeleteKeys();
    }
    if (this.props.forcedItemToFocus && this.props.forcedItemToFocus !== oldProps.forcedItemToFocus) {
      this.focusNode(this.props.forcedItemToFocus.pos);
    }
  };

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  updateDeleteKeys = () => {
    this.setState({ deleteKeys: this.props.canDelete ? [46] : [] });
  };

  checkUnreachableNodes = (engineParam) => {
    const engine = engineParam || this.props.engine;
    const { isRouteReachable, idForRoute } = this.props;
    let nodes = {};

    let rootNodes: ItemNodeModel<any>[] = [];
    if (engine) {
      const model = engine.getDiagramModel();
      ({ nodes } = model);
      /* $FlowFixMe */
      rootNodes = Object.values(model.nodes).filter((node) => node.isRoot === true);
    }
    let nodesToReach = [...Object.values(nodes)];
    const reachedNodes: ItemNodeModel<any>[] = [...rootNodes];
    let routesToUse = [];
    if (rootNodes) {
      rootNodes.forEach((rootNode) => {
        routesToUse = routesToUse.concat(rootNode.getLinksFromNode(false));
      });
    }

    const usedRoutes = [];
    const usedRouteIds = [];
    if (rootNodes) {
      rootNodes.forEach((rootNode) => {
        nodesToReach = nodesToReach.filter(
          /* $FlowFixMe */
          (node) => node && node.getId() !== rootNode.getId(),
        );
      });
    }
    let hasChangedRoutes = true;
    while (routesToUse.length > 0 && nodesToReach.length > 0 && hasChangedRoutes) {
      const newAccessibleNodes = [];
      const pendingRoutes = [];
      hasChangedRoutes = false;
      // Calc all the new accessible nodes
      /* eslint-disable no-loop-func */
      routesToUse.forEach((route) => {
        if (!isRouteReachable || isRouteReachable(route, usedRouteIds, reachedNodes)) {
          const inPort = route.getInPort();
          if (inPort) {
            if (!reachedNodes.includes(inPort.parent)) {
              // TODO : may check if route makes node reachable issue #69
              reachedNodes.push(inPort.parent);
              nodesToReach = nodesToReach.filter(
                (node) => node && node instanceof ItemNodeModel && node.getId() !== inPort.parent.getId(),
              );
            }
            newAccessibleNodes.push(inPort.parent);
          }
          // Mark route as used
          usedRoutes.push(route);
          if (idForRoute) {
            usedRouteIds.push(idForRoute(route));
          }
          hasChangedRoutes = true;
        } else {
          pendingRoutes.push(route);
        }
      });
      /* eslint-enable no-loop-func */

      routesToUse = pendingRoutes;
      // Calc the new accessible routes
      /* eslint-disable no-loop-func */
      newAccessibleNodes.forEach((node) => {
        const routes = node.getLinksFromNode(false);
        routes.forEach((route) => {
          if (
            !usedRoutes.find((routeUsed) => route.id === routeUsed.id) &&
            !routesToUse.find((routeUsed) => route.id === routeUsed.id)
          ) {
            routesToUse.push(route);
          }
        });
      });
      /* eslint-enable no-loop-func */
    }

    routesToUse.forEach((route) => {
      if (!isRouteReachable || isRouteReachable(route, usedRouteIds, reachedNodes)) {
        const inPort = route.getInPort();
        if (inPort) {
          if (!reachedNodes.includes(inPort.parent)) {
            // TODO : may check if route makes node reachable
            reachedNodes.push(inPort.parent);
            nodesToReach = nodesToReach.filter(
              (node) => node && node instanceof ItemNodeModel && node.getId() !== inPort.parent.getId(),
            );
          }
        }
      }
    });

    nodesToReach.forEach((node) => {
      if (node && node.setReachable) {
        /* $FlowFixMe */
        node.setReachable(false);
      }
    });
    reachedNodes.forEach((node) => {
      node.setReachable(true);
    });
  };

  updateLink = (link) => {
    const { linksData } = this.state;
    const { engine } = this.props;
    const currentData = linksData.find((elem) => elem.link.id === link.id);
    let newData;

    if (engine) {
      const inPort = link.getInPort();
      const outPort = link.getOutPort();
      if (inPort && outPort) {
        const outNode = outPort.parent;
        const inNode = inPort.parent;
        newData = {
          out: outNode && outNode.nodeId,
          in: inNode && inNode.nodeId,
          link,
        };
        if (!currentData) {
          this.addItemLink(outNode, inNode, link);
        } else if (newData.in !== currentData.in || newData.out !== currentData.out) {
          this.addItemLink(outNode, inNode, link);
        }
      }
    }
  };

  addItemLink(source: ItemNodeModel<any>, target: ItemNodeModel<any>, link: ItemLinkModel) {
    const { addItemLink, engine } = this.props;
    if (addItemLink) {
      addItemLink(source, target, link, engine && engine.getDiagramModel());
    }
    this.checkUnreachableNodes();
  }

  reload = () => {
    const engine = this.loadGraph();
    this.props.setEngine(engine);
  };

  addItem = (event, dataToUse?: { type: string }) => {
    const { engine } = this.props;
    const { createNodeModelFromItem, createDraggedNodeData, addNodeToRedux } = this.props;
    const data = dataToUse ?? JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
    const points = engine && engine.getRelativeMousePoint(event);
    let item;

    if (createDraggedNodeData) {
      item = createDraggedNodeData(data, points);
    }
    if (!item) {
      return;
    }
    let node;
    if (createNodeModelFromItem) {
      node = createNodeModelFromItem(item);
    }
    if (addNodeToRedux) {
      addNodeToRedux(item, node);
    }
    if (engine) {
      engine.getDiagramModel().addNode(node);
    }
    this.forceUpdate();
  };

  nodesUpdated = (event) => {
    event.node.addListener(this.itemListener);
  };

  loadGraph = () => {
    const engine = new DiagramEngine();
    engine.installDefaultFactories();
    const messageNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Message);
    const entryPointNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.EntryPoint);
    const answerNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Answer);
    const triggerNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.Trigger);
    const baseItemNodeFactory = new DiscussionNodeFactory(ItemNodeTypes.BaseItem);
    const triggeredItemNodeFactory = new DiscussionNodeFactory('TriggeredItem');

    const linkFactory = new ItemLinkFactory();
    const portFactory = new ItemPortFactory();

    engine.registerNodeFactory(messageNodeFactory);
    engine.registerNodeFactory(answerNodeFactory);
    engine.registerNodeFactory(triggerNodeFactory);
    engine.registerNodeFactory(entryPointNodeFactory);
    engine.registerNodeFactory(baseItemNodeFactory);
    engine.registerNodeFactory(triggeredItemNodeFactory);

    engine.registerLinkFactory(linkFactory);
    engine.registerPortFactory(portFactory);

    // setup the diagram model
    const model = new DiagramModel();
    model.addListener(this);

    const { loadGraphNodes, loadGraphLinks } = this.props;
    if (loadGraphNodes) {
      const nodes: { [s: string]: ItemNodeModel<any> } = loadGraphNodes();
      model.addAll(...Object.values(nodes));
      if (nodes && loadGraphLinks) {
        const { links, linksData } = loadGraphLinks(nodes, model);
        links.forEach((link) => {
          link.addListener(this.linkListener);
        });
        model.addAll(...links);
        this.setState({ links, linksData });
      }
    }
    // add all to the main model
    const { preferences } = this.props;
    if (preferences && (!!preferences.zoom || !!preferences.x)) {
      if (preferences.zoom) {
        model.setZoomLevel(preferences.zoom);
      }
      if (preferences.x) {
        model.setOffset(preferences.x, preferences.y);
      }
    } else {
      // We center the items for the user
      model.setOffset(window.innerWidth * 0.3, 0);
    }
    model.addListener(this.modelUpdated);

    // load model into engine and render
    engine.setDiagramModel(model);

    this.checkUnreachableNodes(engine);

    return engine;
  };

  actionStarted = () => true;

  actionFinished = (action) => {
    if (action instanceof MoveItemsAction && action.selectionModels) {
      const { itemNodeMoved } = this.props;
      if (itemNodeMoved) {
        action.selectionModels.forEach((item) => {
          const { model } = item;
          const newPos = { x: model.x, y: model.y };
          if (model && model.item) {
            itemNodeMoved(model, newPos);
          }
        });
      }
    }
    return true;
  };

  zoomToFit = () => {
    const { engine } = this.props;
    if (engine) {
      engine.zoomToFit();
    }
  };

  focusNode = (node: ItemNodeModel<any>) => {
    const { engine } = this.props;
    if (engine) {
      const model = engine.getDiagramModel();
      if (model) {
        const z = model.getZoomLevel() / 100.0;
        model.setOffset(-node.x * z + engine.canvas.offsetWidth / 2 - 100, -node.y * z + 20);
        engine.repaintCanvas();
      }
    }
  };

  render() {
    const { engine } = this.props;
    const { hasDetachedNodes, draggableWidgets, t } = this.props;
    return (
      <div className="fill">
        {hasDetachedNodes && (
          <div className="alert alert-warning mb-0 graph-alert" role="alert">
            {t('general.inccessibleElements')}
          </div>
        )}
        <div className="fill">
          <div id="widgets">{draggableWidgets}</div>
          {engine && (
            <div
              className="fill"
              onDrop={this.addItem}
              onDragOver={(event) => {
                event.preventDefault();
              }}
            >
              <DiagramWidget
                className="fill diagram-view"
                diagramEngine={engine}
                actionStartedFiring={this.actionStarted}
                actionStoppedFiring={this.actionFinished}
                deleteKeys={this.state.deleteKeys}
                allowLooseLinks={false}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default compose(withTranslation('default'))(GraphView);
