/**
 * @flow
 *
 * @format
 */

export const CITIES_CREATE = 'configuration/CITIES_CREATE';
export const CITIES_UPDATE = 'configuration/CITIES_UPDATE';
export const CITIES_REMOVE = 'configuration/CITIES_REMOVE';
export const CITIES_CLEAN = 'configuration/CITIES_CLEAN';
export const LOAD_SCREENPLAY = 'configuration/LOAD_SCREENPLAY';
export const LOAD_SERVER_CONFIG = 'configuration/LOAD_SERVER_CONFIG';
