/**
 * @flow
 * @format
 */
import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import type { AtlEditorState } from 'src/store';
import type { ScenarioReducerState } from 'src/store/scenario';
import * as ROUTES from 'src/constants/routes';
import { Singleton as LocaleManager } from 'src/store/scenario/header/ScenarioLocaleManager';
import { NPCSEditionScreen } from './npcs';
import { DiscussionEditionScreen } from './items/discussion';
import { ScenarioHeaderEditionScreen } from './header';
import { ScenarioItemsEditionScreen } from './items';
import ExportScenarioScreen from './ExportScenarioScreen';
import ScenarioTranslationsScreen from './translations/ScenarioTranslationsScreen';

type Props = {
  scenario: ScenarioReducerState,
  scenarioId: string,
};

const ScenarioEditionRoutes = ({ scenario, scenarioId }: Props) => {
  /**
   * Updates the available locales according to the ones of the scenario, to display all of them in the translation screen
   */
  React.useEffect(() => {
    if (scenario && scenario.header && scenario.header.id) {
      const { managedLocales } = scenario.header;
      LocaleManager.configure(managedLocales[0], managedLocales);
    }
  }, [scenarioId]);

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={ROUTES.SCENARIO_EDITION} component={ScenarioItemsEditionScreen} />
        <Route exact path={ROUTES.SCENARIO_NPCS} component={NPCSEditionScreen} />
        <Route exact path={ROUTES.SCENARIO_HEADER} component={ScenarioHeaderEditionScreen} />
        <Route exact path={ROUTES.SCENARIO_TRADS} component={ScenarioTranslationsScreen} />
        <Route exact path={ROUTES.SCENARIO_EXPORTS} component={ExportScenarioScreen} />
        <Route exact path={ROUTES.SCENARIO_DISCUSSION_EDITION} component={DiscussionEditionScreen} />
      </Switch>
    </React.Fragment>
  );
};

const mapStateToProps = (state: AtlEditorState) => ({
  scenario: state.scenario,
  scenarioId: state.scenario.header.id,
  items: (state.scenario && state.scenario.items && Object.values(state.scenario.items)) || [],
});

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ScenarioEditionRoutes);
