/**
 * @flow
 * @format
 */

import * as React from 'react';
import { InputString } from 'src/pages/components';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';

type Props = {
  year: string,
  updateDepartureScenario: OnboardingServiceHelper.UpdateDepartureScenarioType,
  t: (key: string) => string,
};

const OnboardingDeparture = ({ year, updateDepartureScenario, t }: Props) => {
  return (
    <div className="p-4">
      <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
      <div className="d-flex flex-column">
        <p>{t('screens.newScenario.onboarding.departureScreen.explanationDeparture')}</p>
        <InputString
          className="pt-2 pb-2"
          value={year}
          handleChange={(event) => updateDepartureScenario(event.target.value)}
          fieldName="departureTime"
          help={t('screens.newScenario.onboarding.departureScreen.helpDeparture')}
        />
        {Number.isNaN(Number(year)) && (
          <p className="font-weight-bold pl-2 text-danger">
            {t('screens.newScenario.onboarding.departureScreen.errorDeparture')}
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  year: state.scenario.onboarding.year,
});

const mapDispatchToProps = {
  updateDepartureScenario: OnboardingServiceHelper.updateDepartureScenario,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('default'))(OnboardingDeparture);
