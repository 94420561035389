/**
 * @flow
 *
 * @format
 */
import AtlGraphNode from './AtlGraphNode';
import type { ObjectMap } from './Shortcuts';
import type { AppAtlObject } from './AtlObject';
import BaseItem, { ItemTypes } from './BaseItem';

/* ***************************
  CLASS
*************************** */
export const TriggeredItemConditions = {
  Added: 'Added',
  Discovered: 'Discovered',
  FirstReach: 'FirstReach',
  Reached: 'Reached',
  Launched: 'Launched',
  Unlocked: 'Unlocked',
  Failed: 'Failed',
  Paused: 'Paused',
  PauseResumed: 'PauseResumed',
  Completed: 'Completed',
  Removed: 'Removed',
};

export type TriggeredItemConditionsType = $Values<typeof TriggeredItemConditions>;

export type AppTriggeredItem = AppAtlObject & {
  condition: TriggeredItemConditionsType,
  conditionValue: ?number,
  timer: ?number,
  newTriggeredCondition: ?TriggeredItemConditionsType,
  newTriggeredConditionValue: ?number,
  disableNotif: ?boolean,
};

export default class TriggeredItem extends AtlGraphNode<AppTriggeredItem> {
  condition: ?TriggeredItemConditionsType;

  conditionValue: ?number;

  timer: ?number;

  newTriggeredCondition: ?TriggeredItemConditionsType;

  newTriggeredConditionValue: ?number;

  disableNotif: ?boolean;

  constructor(json: any) {
    super(json, json instanceof TriggeredItem);
    this.condition = json.condition;
    if (json.conditionValue && typeof json.conditionValue === 'string') {
      this.conditionValue = parseInt(json.conditionValue, 10);
    } else if (typeof json.conditionValue === 'number') {
      this.conditionValue = json.conditionValue;
    } else {
      this.conditionValue = undefined;
    }
    if (json.timer && typeof json.timer === 'string') {
      this.timer = parseInt(json.timer, 10);
    } else if (typeof json.timer === 'number') {
      this.timer = json.timer;
    } else {
      this.timer = undefined;
    }
    this.newTriggeredCondition = json.newTriggeredCondition || TriggeredItemConditions.Added;
    if (json.newTriggeredConditionValue && typeof json.newTriggeredConditionValue === 'string') {
      this.newTriggeredConditionValue = parseInt(json.newTriggeredConditionValue, 10);
    } else if (typeof json.newTriggeredConditionValue === 'number') {
      this.newTriggeredConditionValue = json.newTriggeredConditionValue;
    } else {
      this.newTriggeredConditionValue = undefined;
    }
    if (json.disableNotif) {
      this.disableNotif = true;
    }
  }

  serializeInheritedFieldsForApp() {
    const res = {
      ...super.serializeInheritedFieldsForApp(),
      condition: this.condition ? this.condition : undefined,
      conditionValue: undefined,
      newTriggeredCondition: this.newTriggeredCondition ? this.newTriggeredCondition : undefined,
      newTriggeredConditionValue: undefined,
    };
    if (this.timer) {
      res.timer = this.timer;
    }
    if (this.disableNotif) {
      res.disableNotif = this.disableNotif;
    }
    if (this.condition === TriggeredItemConditions.Unlocked) {
      res.conditionValue = this.conditionValue;
    }

    if (this.newTriggeredCondition === TriggeredItemConditions.Unlocked) {
      res.newTriggeredConditionValue = this.newTriggeredConditionValue;
    }
    return res;
  }

  initConditionIfNeeded(parentType: string, childType: string, addTriggeredConditionsItem?: boolean) {
    if (!this.condition) {
      switch (parentType) {
        case ItemTypes.Start:
          this.condition = TriggeredItemConditions.Added;
          break;
        case ItemTypes.POI:
          if (addTriggeredConditionsItem) {
            switch (childType) {
              case ItemTypes.Document:
              case ItemTypes.Discussion:
                this.condition = TriggeredItemConditions.Launched;
                break;
              case ItemTypes.Anecdote:
                this.condition = TriggeredItemConditions.Discovered;
                break;
              default:
            }
          }
          break;
        case ItemTypes.Discussion:
          if (addTriggeredConditionsItem) {
            this.condition = TriggeredItemConditions.Completed;
            this.newTriggeredCondition = TriggeredItemConditions.Completed;
          }
          break;
        default:
      }
    }
  }

  serializeInheritedFields() {
    const res: any = this.serializeInheritedFieldsForApp();
    res.nodeId = this.nodeId;
    res.pos = { ...this.pos };
    return res;
  }

  checkRelease(sourceItem: BaseItem, items: ObjectMap<BaseItem>, startItemId: string) {
    const res = [];
    if (!this.id) {
      res.push({
        key: this.nodeId,
        level: 'error',
        item: this.id,
        message: 'E_NO_TRIGGER_ID',
      });
    } else if (!items[this.id]) {
      res.push({
        key: this.nodeId,
        level: 'error',
        item: this.id,
        message: 'E_TRIGGER_NONEXISTING_ITEM',
        details: { parent: sourceItem.id, child: this.id },
      });
    }
    if (this.conditionValue === 0 && sourceItem.type === ItemTypes.Discussion) {
      res.push({
        key: this.nodeId,
        level: 'error',
        item: this.id,
        message: 'E_TRIGGER_DISCUSSION_STATE_0',
      });
    }
    if (this.newTriggeredConditionValue === 0 && items[this.id].type === ItemTypes.Discussion) {
      res.push({
        key: this.nodeId,
        level: 'error',
        item: this.id,
        message: 'E_TRIGGERED_STATE_DISCUSSION_STATE_0',
        details: { parent: sourceItem.id, child: this.id },
      });
    }
    try {
      const sourceReachable = sourceItem.calculateAllUnlockedValues(items, startItemId);
      if (!sourceReachable.find((trig) => trig.state === this.condition && trig.value === this.conditionValue)) {
        res.push({
          key: this.nodeId,
          level: 'warn',
          item: this.id,
          message: 'E_TRIGGER_UNREACHABLE',
          details: { parent: sourceItem.id, child: this.id },
        });
      }
    } catch (error) {}
    // TODO : Check triggered state valid
    return res;
  }
}
