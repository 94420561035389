/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import './DiscussionGraphView.css';
import { NPC } from 'src/data';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

export type DiscussionContextViewProps = {
  discussion: any,
  npcs: NPC[],
  name: string,
  role: string,
  birthYear: number,
  deathYear: number,
  brief: string,
  linguisticTic: string,
  t: (key: string) => string,
  locale: string,
  city: string,
};

type State = {};

class DiscussionContextView extends React.PureComponent<DiscussionContextViewProps, State> {
  static defaultProps = {
    discussion: {},
  };

  render() {
    const { npcs, city, t, discussion } = this.props;
    const { year } = discussion;
    return (
      <div className="card bg-light screenBlock">
        <div className="card-header">
          <h5 style={{ marginBottom: 0 }}>{t('screens.discussionEdition.context.sectionTitle')}</h5>
        </div>
        <div className="card-body pb-2">
          {(city || year) && (
            <small className={'mb-0 pb-0'}>
              <h6 className="h6 mb-0 mt-0">{t('screens.discussionEdition.context.placeTime')}</h6>
              <p>
                <strong>{city}</strong> ({year})
              </p>
            </small>
          )}
          <h6 className="h6 mb-0 mt-0">{t('screens.discussionEdition.context.npc')}</h6>-
          <small className={'mb-0 pb-0'}>
            <strong>{t('screens.discussionEdition.context.name')}</strong>
            {npcs.map((it) => it.name.valueForLocale(this.props.locale)).join(', ')}
            <br />
          </small>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { discussion } = ownProps;
  const npcId = discussion && discussion.npcId;
  const locale = state.preferences.editionLocale;
  const allNpcs = [...state.scenario.npcs.npcs, ...state.configuration.globalNpcs];
  const npc = allNpcs.find((user) => user.id === npcId);
  const { cityId } = state.scenario.header;
  const city = state.configuration.availableCities.find((it) => it.id === cityId);
  const cityName = city && city.name;
  return {
    npc,
    name: (npc && npc.name.valueForLocale(locale, true)) || npcId,
    role: npc && npc.role,
    birthYear: npc && npc.birthYear,
    deathYear: npc && npc.deathYear,
    brief: npc && npc.brief,
    linguisticTic: npc && npc.linguisticTic,
    city: cityName && cityName.valueForLocale(locale, true),
  };
};

export default compose(connect(mapStateToProps, undefined), withTranslation('default'))(DiscussionContextView);
