/**
 * @flow
 *
 * @format
 */
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import Firebase, { withFirebase } from 'src/services/Firebase';
import { UserServiceHelper } from 'src/store/user';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';
import type { AtlEditorState } from 'src/store';
import type { Team } from 'src/data/types/TeamType';
import { numberToDisplay, limitOrMax } from 'src/data/types/TeamType';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputString, Loader } from 'src/pages/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import EditTeamCreatorInfo from './EditTeamCreatorInfo';

export type UserViewProps = {
  team: Team,
  firebase: Firebase,
  includesMembers?: boolean,
  email: string,
  setTeamDisplayed?: (key?: Team) => void,
  getTeamData: TeamServiceHelper.getTeamDataType,
  updateTeamAppTesters: UserServiceHelper.updateTeamAppTestersType,
  updateTeamStudioEditors: UserServiceHelper.updateTeamStudioEditorsType,
  t: (key: string) => string,
};

const EditTeamMembersView = (props: UserViewProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [team, setTeam] = useState<?Team>();
  const [matricule, setMatricule] = useState<string>('');
  const [emailToAdd, setEmailToAdd] = useState<string>('');
  const [creatorName, setCreatorName] = useState<string>(props.team.creatorName);
  const [webCreatorURL, setWebCreatorURL] = useState<string>(props.team.webCreatorURL);

  useEffect(() => {
    setTeam(props.team);
  }, [props.team]);

  /**
   * Add a new tester for the current team
   */
  const addAppTester = async () => {
    const { firebase } = props;
    setLoading(true);
    if (props.updateTeamAppTesters && matricule.length > 0) {
      const updatedTeam: ?Team = await props.updateTeamAppTesters(props.team.uid, matricule, undefined, firebase);
      if (updatedTeam) {
        setMatricule('');
        // eslint-disable-next-line no-unused-expressions
        props.setTeamDisplayed ? props.setTeamDisplayed(updatedTeam) : setTeam(updatedTeam);
      }
    }
    setLoading(false);
  };

  /**
   * Removes the given user from the testers
   * @param {string} userId
   */
  const removeAppTester = (userId: string) => async () => {
    const { firebase } = props;
    setLoading(true);
    if (props.updateTeamAppTesters) {
      const updatedTeam: ?Team = await props.updateTeamAppTesters(props.team.uid, undefined, userId, firebase);
      if (updatedTeam) {
        // eslint-disable-next-line no-unused-expressions
        props.setTeamDisplayed ? props.setTeamDisplayed(updatedTeam) : setTeam(updatedTeam);
      }
    }
    setLoading(false);
  };

  /**
   * Add a new user to the editor list
   */
  const addStudioEditor = async () => {
    const { firebase } = props;
    setLoading(true);
    if (props.updateTeamStudioEditors && emailToAdd.length > 0) {
      const updatedTeam: ?Team = await props.updateTeamStudioEditors(props.team.uid, emailToAdd, undefined, firebase);
      if (updatedTeam) {
        setEmailToAdd('');
        // eslint-disable-next-line no-unused-expressions
        props.setTeamDisplayed ? props.setTeamDisplayed(updatedTeam) : setTeam(updatedTeam);
      }
    }
    setLoading(false);
  };

  /**
   * Removes the given user from the team editor list
   * @param {string} userId
   */
  const removeStudioEditor = (userId: string) => async () => {
    const { firebase } = props;
    setLoading(true);
    if (props.updateTeamStudioEditors) {
      const updatedTeam: ?Team = await props.updateTeamStudioEditors(props.team.uid, undefined, userId, firebase);
      if (updatedTeam) {
        // eslint-disable-next-line no-unused-expressions
        props.setTeamDisplayed ? props.setTeamDisplayed(updatedTeam) : setTeam(updatedTeam);
      }
    }
    setLoading(false);
  };

  const handleChange = (event) => {
    setMatricule(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailToAdd(event.target.value);
  };

  const { t, includesMembers } = props;
  return team ? (
    <div className="">
      {loading && <Loader />}
      {includesMembers ? (
        <h5 className="mr-3 ml-3 mt-3">{t('screens.settings.user.teamName') + team.name}</h5>
      ) : (
        <div>
          <div className="text-info font-italic">
            {`${t('screens.admin.teams.scenarioLimit') + numberToDisplay(team, 'scenarioIds')}${limitOrMax(
              team,
              'scenarioIds',
            )}`}
          </div>
          <EditTeamCreatorInfo
            creatorName={creatorName}
            webCreatorURL={webCreatorURL}
            setCreatorName={setCreatorName}
            setWebCreatorURL={setWebCreatorURL}
            includesMembers={includesMembers}
            setLoading={setLoading}
            team={team}
            setTeam={setTeam}
          />
        </div>
      )}
      <div className="mr-3 ml-3 mt-3">
        <strong>{t('screens.settings.user.teamTesters')}</strong>
        <div className="text-info font-italic">
          {`${t('screens.settings.user.testersLimit') + numberToDisplay(team, 'appTestersData')}${limitOrMax(
            team,
            'appTestersData',
          )}`}
        </div>
        {team.appTestersData &&
          team.appTestersData.map((it, index) => (
            <div key={`${it.uid}testers${index}`}>
              {it.email} {it.collarNumber}
              <button className="btn btn-light" type="button">
                <FontAwesomeIcon icon={['fad', 'times']} className="icon" onClick={removeAppTester(it.uid)} />
              </button>
            </div>
          ))}
      </div>

      <div className="mr-3 ml-3 mt-3 ">
        <InputString
          inlineTitle
          prefix="#"
          fieldName="matricule"
          value={matricule}
          label={t('screens.settings.user.matriculeTitle')}
          help={t('screens.settings.user.matriculeHelp')}
          handleChange={handleChange}
          suffix={
            <button
              className={'btn mb-0 '}
              type="button"
              id="button-addon2"
              onClick={addAppTester}
              disabled={loading || matricule.length <= 0}
            >
              {t('screens.settings.user.updateTeamAppTesters')}
            </button>
          }
        />
      </div>
      <div className="mr-3 ml-3 mt-3">
        <strong>{t('screens.settings.user.teamEditors')}</strong>
        <div className="text-info font-italic">
          {`${t('screens.settings.user.editorsLimit') + numberToDisplay(team, 'studioEditorsData')}${limitOrMax(
            team,
            'studioEditorsData',
          )}`}
        </div>
        {team.studioEditorsData &&
          team.studioEditorsData.map((it, index) => (
            <div key={`${it.uid}members${index}`}>
              {it.email}
              <button className="btn btn-light" type="button">
                <FontAwesomeIcon icon={['fad', 'times']} className="icon" onClick={removeStudioEditor(it.uid)} />
              </button>
            </div>
          ))}
      </div>

      <div className="mr-3 ml-3 mt-3 ">
        <InputString
          inlineTitle
          fieldName="email"
          value={emailToAdd}
          label={t('screens.settings.user.emailTitle')}
          help={t('screens.settings.user.emailHelp')}
          handleChange={handleEmailChange}
          suffix={
            <button
              className={'btn mb-0 '}
              type="button"
              id="button-addon2"
              onClick={addStudioEditor}
              disabled={loading || emailToAdd.length <= 0}
            >
              {t('screens.settings.user.updateTeamStudioEditors')}
            </button>
          }
        />
      </div>
    </div>
  ) : (
    <>{t('screens.admin.teams.nothingToDisplay')}</>
  );
};

const mapStateToProps = (state: AtlEditorState) => ({
  email: state.user.user && state.user.user.email,
});

const mapDispatchToProps = {
  updateTeamAppTesters: UserServiceHelper.updateTeamAppTesters,
  updateTeamStudioEditors: UserServiceHelper.updateTeamStudioEditors,
  getTeamData: TeamServiceHelper.getTeamData,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(EditTeamMembersView);
