/**
 * @flow
 * @format
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as moment from 'moment';
import * as Constants from '../../constants/globals';
import 'moment/locale/fr';

// Languages available on the editor, the first one is the default value
import { availableLanguagesEditor } from './languages';

/**
 * Initialize i18n with the language of the browser, and change the locale with this
 */
const i18nInit = async () => {
  await i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
      fallbackLng: availableLanguagesEditor,
      backend: {
        // for all available options read the backend's repository readme file
        loadPath: '/locales/{{lng}}/{{ns}}.json',
      },

      // have a common namespace used around the full app

      debug: false, // !Constants.production,

      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react-i18next options
      react: {
        useSuspense: true,
      },
      // Used to remove dynamic keys that are useless and never used
      parseMissingKeyHandler: () => '',
    });
  moment.locale(i18n.language);
};
i18nInit();

/**
 * Function used to change the language of the editor
 */
export function changeLocale(locale: string) {
  if (Constants.enableHelperLogger) console.debug(`Locale changed to '${locale}'`);
  i18n.changeLanguage(locale);
  moment.locale(locale);
}

/**
 * Function used to map the given language and get the country associated
 */
export const languageToFlag = (lng: string): string => {
  const language: string = lng.split('-')[0];
  // Used to specify the languages that do not have the same id for the language and the country
  switch (language) {
    case 'fr':
      return 'fr';
    case 'en':
      return 'gb';
    default:
      return lng;
  }
};

export { i18n, moment };
