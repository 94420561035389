/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

type TabContentProps = { name: string, active?: boolean, children: React.Node };
const TabContent = ({ name, active = false, children }: TabContentProps) => (
  <div
    className={`tab-pane fade ${active ? 'show active' : ''}`}
    style={{ width: '100%' }}
    id={name}
    role="tabpanel"
    aria-labelledby={`${name}-tab`}
  >
    {children}
  </div>
);

export default TabContent;
