/**
 * @flow
 * @format
 */
import React from 'react';
import Lottie from 'react-lottie-player';
import creatingJSON from 'src/assets/lottie/creating.json';

const Creating = () => {
  return (
    <div className="loaderContainer" style={{ zIndex: 3000 }}>
      <Lottie loop animationData={creatingJSON} play style={{ height: '30vh' }} />
    </div>
  );
};

export default Creating;
