/**
 * @flow
 * @format
 */
import * as React from 'react';
import type { ComponentType } from 'react';

const EditionSearchContext: Object = React.createContext(null);

export type withModalProps = {
  showModal: (content: React.Node, cancelable: boolean, onHide?: () => any, modalClass?: strig) => any | void,
  hideModal: () => any | void,
};

type Props = {
  children: React.Node,
};
const EditionSearchProvider = (props: Props) => {
  const [handlers, setHandlers] = React.useState([]);

  const enableFilter = (key: string, handler: (item: any) => boolean) => {
    if (!handlers.find((it) => it.key === key)) {
      setHandlers([...handlers, { key, handleItem: handler }]);
    }
  };

  const disableFilter = (key: string) => {
    setHandlers(handlers.filter((it) => it.key !== key));
  };
  return (
    <EditionSearchContext.Provider
      value={{
        hasHandlers: handlers.length,
        handlers: handlers.map((it) => it.handleItem),
        disableFilter,
        enableFilter,
      }}
    >
      {props.children}
    </EditionSearchContext.Provider>
  );
};

// eslint-disable-next-line arrow-parens
const withEditionSearch = <PassedProps: {}>(
  WrappedComponent: ComponentType<PassedProps>,
): ComponentType<$Diff<PassedProps, withModalProps>> => {
  class WithEditionSearch extends React.Component<PassedProps> {
    modaleCallbacks = undefined;

    render() {
      return (
        <EditionSearchContext.Consumer>
          {(contextProps) => <WrappedComponent {...this.props} {...contextProps} />}
        </EditionSearchContext.Consumer>
      );
    }
  }

  return WithEditionSearch;
};

export { EditionSearchProvider };

export default withEditionSearch;
