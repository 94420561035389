/**
 * @flow
 * @format
 */
/* global google */
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as markers from 'fontawesome-markers';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { Constants } from 'src/assets';
import * as OnboardingServiceHelper from 'src/store/scenario/onboarding/OnboardingServiceHelper';

type MyMapComponentProps = {
  onMapClick: (event: Event) => void,
  showMarker: boolean,
  startMarker: { latitude: number, longitude: number },
  defaultZoom: number,
  onMarkerDragged: (id: string, lat: number, lng: number, alt?: number) => void,
};

const MyMapComponent = withScriptjs(
  withGoogleMap(({ onMapClick, showMarker, startMarker, defaultZoom, onMarkerDragged }: MyMapComponentProps) => (
    <GoogleMap
      defaultZoom={defaultZoom}
      mapTypeId={'hybrid'}
      defaultCenter={{ lat: startMarker.latitude, lng: startMarker.longitude }}
      options={{ streetViewControl: false }}
      onClick={onMapClick}
    >
      {showMarker && (
        <Marker
          options={{
            draggable: true,
          }}
          icon={{
            path: markers.default.HOME,
            scale: 0.5,
            fillColor: '#00E0CC',
            fillOpacity: 1,
            strokeColor: '',
            strokeWeight: 0,
            // $FlowFixMe Google
            anchor: new google.maps.Point(25, 0),
          }}
          position={{ lat: startMarker.latitude, lng: startMarker.longitude }}
          onDragEnd={onMarkerDragged}
        />
      )}
    </GoogleMap>
  )),
);

type OnboardingMapProps = {
  startMarker: { latitude: number, longitude: number },
  updateCoordinates: OnboardingServiceHelper.UpdateCoordinatesType,
};

const OnboardingMap = ({ startMarker, updateCoordinates }: OnboardingMapProps) => {
  const [markerToDisplay, setMarkerToDisplay] = React.useState<boolean>(false);

  React.useEffect(() => {
    setMarkerToDisplay(startMarker.latitude !== 0 || startMarker.longitude !== 0);
  }, [startMarker]);

  return (
    <MyMapComponent
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Constants.mapsApiKey}&v=3.exp&libraries=geometry,drawing,places,elevation`}
      loadingElement={<div style={{ height: '100%' }} />}
      containerElement={<div style={{ height: '55vh' }} />}
      mapElement={<div style={{ height: '55vh' }} />}
      onMapClick={({ latLng }) => updateCoordinates(latLng.lat(), latLng.lng())}
      startMarker={startMarker}
      showMarker={markerToDisplay}
      defaultZoom={markerToDisplay ? 15 : 1.5}
      onMarkerDragged={({ latLng }) => updateCoordinates(latLng.lat(), latLng.lng())}
    />
  );
};

const mapStateToProps = (state) => ({
  startMarker: state.scenario.onboarding.coordinate,
});

const mapDispatchToProps = {
  updateCoordinates: OnboardingServiceHelper.updateCoordinates,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OnboardingMap);
