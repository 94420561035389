/**
 * @flow
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedFile from './LocalizedFile';

export default class BackgroundMusicItem extends BaseItem {
  musicFile: LocalizedFile;

  volume: number;

  constructor(json: any) {
    if (json instanceof BackgroundMusicItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { musicFile, volume } = json;
    this.type = ItemTypes.BackgroundMusic;
    this.musicFile = new LocalizedFile(this.id, 'musicFile', musicFile);
    this.volume = volume !== undefined ? volume : 100;
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    if (this.musicFile && this.musicFile.hasFile()) {
      res.musicFile = this.musicFile.serializeForApp();
    }
    res.volume = this.volume;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.musicFile = this.musicFile && this.musicFile.serialize();
    res.volume = this.volume;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();

    if (this.musicFile.hasFile()) {
      res.musicFile = this.musicFile;
    }
    return res;
  }
}
