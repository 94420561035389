/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { languageToFlag } from 'src/assets/locales';
import Flag from 'react-world-flags';

type LanguagesDropdownProps = {
  availableLanguages: Array<string>,
  currentLanguage: string,
  idDropdown: string,
  changeLanguage: (lng: string) => void,
};

const LanguagesDropdown = ({
  availableLanguages,
  currentLanguage,
  idDropdown,
  changeLanguage,
}: LanguagesDropdownProps) => {
  return (
    <div className="dropdown">
      <button className="btn dropdown-toggle bg-light" href="#" id={idDropdown} data-toggle="dropdown" type="button">
        <Flag code={languageToFlag(currentLanguage)} width="40" className="rounded border" />
      </button>
      <ul className="dropdown-menu" aria-labelledby="languagesDropdown">
        {availableLanguages
          .filter((lng) => lng !== currentLanguage.split('-')[0])
          .map((language, index) => (
            <li className="dropdown-item" key={`lng${index + language}`}>
              <a role="button" onClick={() => changeLanguage(language)}>
                <div className="d-flex flex-row justify-content-center w-100 align-items-center">
                  <div className="col-6 ml-2 d-flex justify-content-center">
                    <span className="align-bottom">{language}</span>
                  </div>
                  <div className="col-6 d-flex justify-content-center mr-2">
                    <Flag code={languageToFlag(language)} className="h-100 w-100 rounded border" />
                  </div>
                </div>
              </a>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default compose(withTranslation('default'))(LanguagesDropdown);
