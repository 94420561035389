/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';

/* ***************************
  CLASS
*************************** */
export type AppSecondaryMission = {};

export default class SecondaryMissionItem extends BaseItem {
  constructor(json: any) {
    if (json instanceof SecondaryMissionItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, ...newMeta } = json;
    this.type = ItemTypes.SecondaryMission;
    this.setMeta({ ...json.meta, ...newMeta });
  }
}
