/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Scenario, BaseItem, AtlObject, NPC } from 'src/data';
import type { AtlEditorState } from 'src/store';
import { ItemsServiceHelper } from 'src/store/scenario/items';
import { NPCServiceHelper } from 'src/store/scenario/npcs';
import { HeaderServiceHelper } from 'src/store/scenario/header';
import { ScenarioServiceHelper } from 'src/store/scenario';
import type { ScenarioReducerState } from 'src/store/scenario';
import type { ItemsReducerState } from 'src/store/scenario/items';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';
import TranslationsScreen from 'src/pages/components/translation/TranslationsScreen';
import type { TranslationsType } from 'src/pages/components/translation/TranslationsScreen';
import Firebase, { withFirebase } from 'src/services/Firebase';

export type TranslationsScreenProps = {
  updateItem: ItemsServiceHelper.updateItemType,
  reduxState: ScenarioReducerState,
  exportTranslations: ScenarioServiceHelper.exportScenarioType,
  applyTranslations: ScenarioServiceHelper.applyTranslationsType,
  updateHeader: HeaderServiceHelper.updateHeaderType,
  updateNPCAsync: NPCServiceHelper.updateNPCAsyncType,
  locale: string,
  scenario: Scenario,
  items: ItemsReducerState,
  npcs: NPC[],
  itemsArray: BaseItem[],
  roomMembers: any[],
  firebase: Firebase,
};

type State = {};

class ScenarioTranslationsScreen extends React.PureComponent<TranslationsScreenProps, State> {
  static defaultProps = {};

  state = {};

  updateItem = async (sectionId: string, part?: string, itemId: string, item: AtlObject<any>) => {
    const { updateItem, updateHeader, updateNPCAsync, firebase, items } = this.props;
    if (part === 'items') {
      if (updateItem && item instanceof BaseItem) {
        await updateItem(sectionId, item.id, item, firebase);
      }
    } else if (part === 'header') {
      if (updateHeader) {
        await updateHeader(item, firebase, items);
      }
    } else if (part === 'npcs' && item instanceof NPC) {
      if (updateNPCAsync) {
        await updateNPCAsync(sectionId, item.id, item);
      }
    }
  };

  applyTranslations = (translations: TranslationsType) => {
    const { reduxState, applyTranslations } = this.props;
    if (applyTranslations) {
      try {
        applyTranslations(reduxState, translations);
      } catch (error) {
        console.log('Could not apply translation', error);
      }
    }
  };

  handleCsvLoaded = async (json: any) => {
    const res = ScenarioServiceHelper.formatTranslation(json);
    return res;
  };

  render() {
    const { itemsArray, reduxState, exportTranslations, npcs } = this.props;
    const { headers, lines } = exportTranslations(reduxState);
    const { header } = reduxState;
    return (
      <TranslationsScreen
        roomMembers={this.props.roomMembers}
        handleCsvLoaded={this.handleCsvLoaded}
        applyTranslations={this.applyTranslations}
        stringTranslationInfos={[
          { id: header.id, item: header, part: 'header' },
          ...itemsArray.map((item) => ({ id: item.id, item, part: 'items' })),
          ...npcs.map((item) => ({ id: item.id, item, part: 'npcs' })),
        ]}
        fileTranslationInfos={[
          { id: header.id, item: header, part: 'header' },
          ...itemsArray.map((item) => ({ id: item.id, item, part: 'items' })),
        ]}
        updateItem={this.updateItem}
        csvData={{ headers, lines }}
        section="scenario"
        translationId={header.id}
      />
    );
  }
}

const mapStateToProps = (state: AtlEditorState) => {
  const itemsRedux = state.scenario.items;
  const { __detachedNodes, _persist, ...others } = itemsRedux;
  return {
    reduxState: state.scenario,
    itemsArray: Object.values(others),
    npcs: state.scenario.npcs.npcs,
    items: itemsRedux,
  };
};

const mapDispatchToProps = {
  exportTranslations: ScenarioServiceHelper.exportScenarioTranlations,
  applyTranslations: ScenarioServiceHelper.applyTranslations,
  updateItem: ItemsServiceHelper.updateItem,
  updateHeader: HeaderServiceHelper.updateHeader,
  updateNPCAsync: NPCServiceHelper.updateNPCAsync,
};

export default withFirebase(
  withUrlProperties(connect(mapStateToProps, mapDispatchToProps)(ScenarioTranslationsScreen)),
);
