/**
 * @flow
 *
 * @format
 */
import { Singleton } from 'src/store/scenario/header/ScenarioLocaleManager';
import type { ObjectMap } from './Shortcuts';

type valuesType = ObjectMap<string[]>;

export const cleanupStringArray = (value: string[]) => {
  const quoteReplacer = (correspondance, p1) => `“${p1}”`;
  const res = value.map((it: string) => {
    let cleaned = it.trim();
    cleaned = cleaned.replace(/[ ]*\\n[ ]*/g, '\n');
    cleaned = cleaned.replace(/"([^"]*)"/, quoteReplacer);
    return cleaned;
  });
  return res;
};
export default class LocalizedStringArray {
  id: string;

  defaultLocale: string;

  translate: boolean;

  values: valuesType;

  constructor(id: string, values: valuesType | LocalizedStringArray = {}, translate: boolean = true) {
    const { defaultLocale, defaultValues } = Singleton.localizedStringArrayParams(translate);
    this.id = id;
    this.translate = translate;
    const valuesToUse: valuesType = values.values ? values.values : values;
    Object.keys(valuesToUse).forEach((locale: string) => {
      valuesToUse[locale] = cleanupStringArray(valuesToUse[locale]);
    });
    this.defaultLocale = defaultLocale;
    this.values = { ...defaultValues, ...valuesToUse };
  }

  valueForLocale(locale: string, defaultIfTrue: boolean = false): string[] {
    const val = this.values[locale];
    if (val || !defaultIfTrue) {
      return val;
    }
    return this.values[this.defaultLocale];
  }

  hasLocale(locale: string) {
    return this.values[locale];
  }

  setValueForLocale(value: string | string[], locale: string) {
    if (typeof value === 'string') {
      this.values[locale] = cleanupStringArray(JSON.parse(value));
    } else {
      this.values[locale] = cleanupStringArray(value);
    }
  }

  initAllLocalesIfRequired() {
    if (this.hasContent()) {
      const { defaultValues } = Singleton.localizedStringArrayParams(true);
      Object.keys(defaultValues).forEach((locale) => {
        if (!this.values[locale]) {
          this.values[locale] = defaultValues[locale];
        }
      });
    }
  }

  serializeForApp() {
    const res = { ...this.values };
    return res;
  }

  serialize() {
    const res = { ...this.values };
    return res;
  }

  checkRelease(locales: string[]): { level: string, item: string, message: string, details?: any }[] {
    const res = [];
    // Check that the content is available in all locales
    if (!this.hasLocale('default')) {
      locales.forEach((loc) => {
        if (loc !== 'default' && !this.hasLocale(loc)) {
          res.push({
            level: 'warn',
            item: this.id,
            message: 'E_FILE_MISSING_IN_LOCALE',
            details: { message: `No translation for ${this.id} in ${loc}` },
          });
        }
      });
    }
    return res;
  }

  hasContent() {
    let res = false;
    Object.keys(this.values).forEach((key) => {
      if (this.values[key].length > 0) {
        res = true;
      }
    });
    return res;
  }

  isEmpty() {
    const res =
      !Object.keys(this.values).length ||
      /* $FlowFixMe: Object.values */
      Object.values(this.values).reduce((acc, cur: string[]) => acc || !cur || cur.length, false) === 0;
    return res;
  }

  requireTranslations() {
    const firstKey = Object.keys(this.values)[0];
    return Object.keys(this.values).length > 1 || (firstKey && firstKey !== 'default');
  }

  applyTranslations(translationObj: { [locale: string]: string }) {
    Object.keys(translationObj).forEach((key) => {
      const csvContent = translationObj[key];
      this.values[key] = cleanupStringArray(csvContent.split('//').map((it) => it.trim()));
    });
  }

  getCSVContent() {
    const res = {};
    Object.keys(this.values).forEach((key) => {
      res[key] = this.values[key].join('//');
    });
    return res;
  }
}
