/**
 * /* @flow
 *
 * @format
 */

import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import LocalizedStringArray from './LocalizedStringArray';

/* ***************************
  CLASS
*************************** */
export type AppImg360 = {};

export default class Image360Item extends BaseItem {
  description: LocalizedString;

  enableTouchTracking: boolean;

  image: LocalizedFile;

  constructor(json: any) {
    if (json instanceof Image360Item) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, description, closable, image, enableTouchTracking, ...newMeta } = json;

    this.image = new LocalizedFile(this.id, 'img', image);
    this.description = new LocalizedString(`${this.id}_description`, description);
    this.type = ItemTypes.Image360;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    if (this.image && this.image.hasFile()) {
      res.image = this.image.serializeForApp();
    }
    res.description = this.description && this.description.serializeForApp();
    res.enableTouchTracking = this.enableTouchTracking;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.image = this.image && this.image.serialize();
    res.description = this.description && this.description.serialize();
    res.enableTouchTracking = this.enableTouchTracking;
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.description = this.description;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();

    if (this.image.hasFile()) {
      res.image = this.image;
    }
    return res;
  }
}
