/**
 * @flow
 *
 * @format
 */
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import AtlObject from './AtlObject';
import type { AppAtlObject } from './AtlObject';

export type AppCity = AppAtlObject & {
  name: LocalizedString,
  cover: LocalizedFile,
  centerCoordinate: { latitude: number, longitude: number },
  radius: number,
};

export default class City extends AtlObject<AppCity> {
  name: LocalizedString;

  cover: LocalizedFile;

  centerCoordinate: { latitude: number, longitude: number };

  radius: number;

  displayOrder: number;

  constructor(json: any) {
    super(json);
    this.name = new LocalizedString(`${this.id}_name`, json.name);
    this.cover = new LocalizedFile(this.id, 'cover', json.cover);
    this.centerCoordinate = { ...json.centerCoordinate };
    this.displayOrder = json.displayOrder;
    this.radius = json.radius;
  }

  serializeInheritedFields() {
    const res = {
      name: this.name.serialize(),
      cover: this.cover.serialize(),
      centerCoordinate: { ...this.centerCoordinate },
      displayOrder: this.displayOrder,
      radius: this.radius,
    };
    return res;
  }

  serializeInheritedFieldsForApp() {
    const res = {
      name: this.name.serializeForApp(),
      cover: this.cover.serializeForApp(),
      centerCoordinate: { ...this.centerCoordinate },
      displayOrder: this.displayOrder,
      radius: this.radius,
    };
    return res;
  }

  getLocalizedFilesWithPath() {
    return {
      ...super.getLocalizedFilesWithPath(),
      cover: this.cover,
    };
  }

  managedLocales(): string[] {
    return Object.keys(this.name.values);
  }
}
