/**
 * @flow
 * @format
 */

import * as React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import OnboardingMap from './OnboardingMap';

type Props = {
  t: (key: string) => string,
};

const OnboardingPosition = ({ t }: Props) => {
  return (
    <div className="p-4">
      <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
      <p>{t('screens.newScenario.onboarding.positionScreen.selectStartPoint')}</p>
      <p>{t('screens.newScenario.onboarding.positionScreen.startPointExplanation')}</p>
      <div className="p-4">
        <OnboardingMap />
      </div>
    </div>
  );
};

export default compose(withTranslation('default'))(OnboardingPosition);
