/**
 * @flow
 *
 * @format
 */

export const CatalogTypes = {
  dev: 'dev',
  prod: 'prod',
};

export type CatalogType = $Values<typeof CatalogTypes>;
