/**
 * @flow
 *
 * @format
 */

export const USER_SET = 'user/USER_SET';
export const LAST_EMAIL_SET = 'user/LAST_EMAIL_SET';
export const MATRICULE_SET = 'user/MATRICULE_SET';
export const USER_UPDATE_CONSENT = 'user/USER_UPDATE_CONSENT';
