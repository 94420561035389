/**
 * @flow
 *
 * @format
 */
import React from 'react';

import { connect } from 'react-redux';

import Firebase, { withFirebase } from 'src/services/Firebase';
import type { AtlEditorState } from 'src/store';
import type { Team } from 'src/data/types/TeamType';

import { numberToDisplay, limitOrMax } from 'src/data/types/TeamType';
import * as TeamServiceHelper from 'src/store/admin/TeamServiceHelper';

import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { InputString } from 'src/pages/components';

export type EditScenariosTeamProps = {
  team: Team,
  firebase: Firebase,
  setTeamDisplayed?: (key?: Team) => void,
  setLoading: (key: boolean) => void,
  setOtherTeamToUpdate: (key: Team) => void,
  updateTeam: TeamServiceHelper.updateTeamType,
  t: (key: string) => string,
};

const numberItemsPerPage = 5;

const EditScenariosTeam = ({
  team,
  firebase,
  setLoading,
  setOtherTeamToUpdate,
  setTeamDisplayed,
  updateTeam,
  t,
}: EditScenariosTeamProps) => {
  const [allInputs, setAllInputs] = React.useState({});
  const [scenariosDisplayed, setScenariosDisplayed] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [lastPage, setLastPage] = React.useState(0);

  /**
   * Updates the pages and scenarios displayed
   */
  React.useEffect(() => {
    const startItem = currentPage * numberItemsPerPage;
    const endItem = startItem + numberItemsPerPage;
    if (team.scenarioIds) {
      setScenariosDisplayed(
        team.scenarioIds.filter((scenarioId, index) => {
          return index >= startItem && index < endItem;
        }),
      );
    } else {
      setScenariosDisplayed([]);
    }
  }, [team, currentPage]);

  /**
   * Init the fields input with empty strings
   */
  React.useEffect(() => {
    if (team.scenarioIds) {
      const newInputsEmpty = {};
      for (let i = 0; i < team.scenarioIds.length; i += 1) {
        newInputsEmpty[team.scenarioIds[i]] = '';
      }
      setAllInputs(newInputsEmpty);
      setLastPage(Math.ceil(team.scenarioIds.length / 5));
    } else {
      setLastPage(0);
    }
    setCurrentPage(0);
  }, [team]);

  const handleChange = (event, scenarioId: string) => {
    setAllInputs({
      ...allInputs,
      [scenarioId]: event.target.value,
    });
  };

  const renderPagination = () => {
    const rows = [];
    for (let i = 0; i < lastPage; i += 1) {
      rows.push(
        <li className="page-item">
          <a className="page-link" onClick={() => setCurrentPage(i)}>
            {i + 1}
          </a>
        </li>,
      );
    }
    return rows.map((item) => item);
  };

  /**
   * Moves the scenario to the new team
   * @param {string} scenarioId
   */
  const changeTeamScenario = async (scenarioId: string) => {
    setLoading(true);
    const newTeamId: string = allInputs[scenarioId];
    if (newTeamId && newTeamId.length && newTeamId !== team.uid) {
      const result = await updateTeam(team.uid, undefined, undefined, undefined, firebase, newTeamId, scenarioId);
      if (result && setTeamDisplayed) {
        const { oldTeam, newTeam } = result;
        // We update the teams displayed
        setTeamDisplayed(oldTeam);
        setOtherTeamToUpdate(newTeam);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <h5 className="mr-3 ml-3 mt-3">{t('screens.admin.teams.scenariosTeamLabel') + team.name}</h5>
      <div className="text-info font-italic">
        {`${t('screens.admin.teams.scenarioLimit') + numberToDisplay(team, 'scenarioIds')}${limitOrMax(
          team,
          'scenarioIds',
        )}`}
      </div>
      {scenariosDisplayed.length > 0 ? (
        <div className="mr-3 ml-3 mt-3">
          {team.scenarioIds && (
            <>
              {scenariosDisplayed.map((scenarioId) => (
                <>
                  <strong>{t('screens.admin.teams.scenarioIdTeamLabel') + scenarioId}</strong>
                  <InputString
                    inlineTitle
                    fieldName={scenarioId}
                    value={allInputs[scenarioId]}
                    help={t('screens.admin.teams.scenarioHelpTeamLabel')}
                    handleChange={(event) => handleChange(event, scenarioId)}
                    suffix={
                      <button
                        className={'btn mb-0 '}
                        type="button"
                        id="button-addon2"
                        onClick={() => changeTeamScenario(scenarioId)}
                      >
                        {t('general.save')}
                      </button>
                    }
                  />
                </>
              ))}
              {scenariosDisplayed.length > 0 && (
                <>
                  <strong className="mt-3 d-flex justify-content-center">
                    {`${numberItemsPerPage * currentPage + 1} - ${numberItemsPerPage * currentPage +
                      scenariosDisplayed.length}`}
                  </strong>
                  <nav className="d-flex justify-content-center">
                    <ul className="pagination">
                      <li className={`page-item${currentPage === 0 ? ' disabled' : ''}`}>
                        <a className="page-link" aria-label="Previous" onClick={() => setCurrentPage(currentPage - 1)}>
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      {renderPagination()}
                      <li className={`page-item${lastPage - 1 === currentPage ? ' disabled' : ''}`}>
                        <a className="page-link" aria-label="Next" onClick={() => setCurrentPage(currentPage + 1)}>
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <p className="ml-3 mr-3">{t('screens.admin.teams.noScenarioTeam')}</p>
      )}
    </>
  );
};

const mapStateToProps = (state: AtlEditorState) => ({
  email: state.user.user && state.user.user.email,
});

const mapDispatchToProps = {
  updateTeam: TeamServiceHelper.updateTeam,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('default'),
)(EditScenariosTeam);
