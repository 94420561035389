/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';

export interface InputStepsProps {
  fieldName: string;
  value?: ?number;
  label: string;
  help?: ?string;
  disabled: boolean;
  min: number;
  max: number;
  step: number;
  unit?: ?string;
  handleChange: (any) => any;
  hidden: boolean;
  separatorBefore?: boolean;
  values?: ?(string[]);
  onFocus?: () => any;
}

export default class InputSteps extends React.Component<InputStepsProps> {
  static defaultProps = {
    disabled: false,
    min: 0,
    max: 100,
    step: 1,
    hidden: false,
    separatorBefore: false,
  };

  render() {
    const {
      fieldName,
      value,
      label,
      help,
      min,
      max,
      step,
      disabled,
      unit,
      handleChange,
      hidden,
      separatorBefore,
      values,
    } = this.props;

    let valueToDisplay;
    if (value && values) {
      valueToDisplay = values[value - 1];
    } else if (value !== undefined && value !== null) {
      valueToDisplay = `${value}`;
    }
    return (
      <div className="form-group" key={fieldName} hidden={hidden}>
        {separatorBefore && <hr />}
        <label htmlFor={fieldName} className="strong text-capitalize">
          {label}
        </label>
        <small id={`${fieldName}value`} className="form-text text-muted">
          {`${valueToDisplay || ''} ${unit || '.'}`}
        </small>
        <input
          type="range"
          className="form-control-range"
          id={fieldName}
          onChange={handleChange}
          value={value}
          disabled={disabled}
          aria-describedby={`${fieldName}Help`}
          placeholder={''}
          step={step}
          min={min}
          max={max}
        />
        {help && (
          <small id={`${fieldName}Help`} className="form-text text-muted">
            {help}
          </small>
        )}
      </div>
    );
  }
}
