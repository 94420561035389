/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import { DiagramEngine, AbstractLinkFactory, DefaultLinkWidget } from 'storm-react-diagrams';
import ItemLinkModel from './ItemLinkModel';

export default class ItemLinkFactory extends AbstractLinkFactory<ItemLinkModel> {
  constructor() {
    super('discussion');
  }

  // eslint-disable-next-line class-methods-use-this
  generateReactWidget(diagramEngine: DiagramEngine, link: ItemLinkModel): React.Node {
    return React.createElement(DefaultLinkWidget, {
      link,
      diagramEngine,
    });
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  getNewInstance(initialConfig?: any): ItemLinkModel {
    return new ItemLinkModel();
  }

  // eslint-disable-next-line class-methods-use-this
  generateLinkSegment(model: ItemLinkModel, widget: DefaultLinkWidget, selected: boolean, path: string) {
    return (
      <path
        className={selected ? widget.bem('--path-selected') : ''}
        strokeWidth={model.width}
        stroke={model.color}
        d={path}
      />
    );
  }
}
