/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

type TabButtonProps = { name: string, text: string, active?: boolean };
const TabButton = ({ name, text, active = false }: TabButtonProps) => (
  <li className="tutorialButton">
    <a
      className={`nav-link ${active ? 'tutorialButtonActive' : ''} tab-button ${name}-icon tutorialButton`}
      id={`${name}-tab`}
      data-toggle="tab"
      data-target={`#${name}`}
      href={`#${name}`}
      role="tab"
      aria-controls={name}
      aria-selected={active ? 'true' : 'false'}
    >
      {text}
    </a>
  </li>
);

export default TabButton;
