/**
 * /* @flow
 *
 * @format
 */

import BaseItem, { ItemTypes } from './BaseItem';
import LocalizedString from './LocalizedString';
import LocalizedFile from './LocalizedFile';
import LocalizedStringArray from './LocalizedStringArray';
import { TriggeredItemConditions } from './TriggeredItem';

/* ***************************
  CLASS
*************************** */
export type AppVideo = {};

export default class VideoItem extends BaseItem {
  closable: boolean;

  autoCloseOnFinish: boolean;

  video: LocalizedFile;

  videoArchiveItemId: string;

  usePresentation: boolean;

  title: LocalizedString;

  description: LocalizedString;

  usePresentation: boolean;

  cover: LocalizedFile;

  useFullArchive: ?boolean;

  constructor(json: any) {
    if (json instanceof VideoItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const {
      type,
      meta,
      description,
      closable,
      autoCloseOnFinish,
      video,
      videoArchiveItemId,
      useFullArchive,
      cover,
      title,
      usePresentation,
      ...newMeta
    } = json;

    this.video = new LocalizedFile(this.id, 'vid', video);
    this.videoArchiveItemId = videoArchiveItemId;
    this.cover = new LocalizedFile(this.id, 'cov', cover);
    this.description = new LocalizedString(`${this.id}_description`, description);
    this.title = new LocalizedString(`${this.id}_title`, title);
    this.closable = closable;
    this.autoCloseOnFinish = autoCloseOnFinish;
    this.usePresentation = usePresentation;
    this.type = ItemTypes.Video;
    this.useFullArchive = useFullArchive;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    if (this.video && this.video.hasFile()) {
      res.video = this.video.serializeForApp();
    }
    if (this.videoArchiveItemId && this.videoArchiveItemId.length) {
      res.videoArchiveItemId = this.videoArchiveItemId;
      res.useFullArchive = this.useFullArchive;
    }
    if (this.cover && this.cover.hasFile()) {
      res.cover = this.cover.serializeForApp();
    }
    res.description = this.description && this.description.serializeForApp();
    res.title = this.title && this.title.serializeForApp();
    res.closable = this.closable;
    res.autoCloseOnFinish = this.autoCloseOnFinish;
    res.usePresentation = this.usePresentation;
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.video = this.video && this.video.serialize();
    if (this.videoArchiveItemId && this.videoArchiveItemId.length) {
      res.videoArchiveItemId = this.videoArchiveItemId;
    }
    res.useFullArchive = this.useFullArchive;
    res.cover = this.cover && this.cover.serialize();
    res.title = this.title && this.title.serialize();
    res.description = this.description && this.description.serialize();
    res.closable = this.closable;
    res.autoCloseOnFinish = this.autoCloseOnFinish;
    res.usePresentation = this.usePresentation;
    return res;
  }

  getLocalizedStringsWithPath() {
    const res: { [path: string]: LocalizedString | LocalizedStringArray } = super.getLocalizedStringsWithPath();
    res.description = this.description;
    res.title = this.title;
    return res;
  }

  getLocalizedFilesWithPath() {
    const res: { [path: string]: LocalizedFile } = super.getLocalizedFilesWithPath();
    if (this.video.hasFile()) {
      res.video = this.video;
    }
    if (this.cover.hasFile()) {
      res.cover = this.cover;
    }
    return res;
  }

  calculateSelfUnlockedValues(startItemId: string) {
    const selfUnlockedValues = [
      {
        state: TriggeredItemConditions.Launched,
      },
      {
        state: TriggeredItemConditions.Completed,
      },
    ];
    return [...super.calculateSelfUnlockedValues(startItemId), ...selfUnlockedValues];
  }
}
