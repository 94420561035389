/**
 * @flow
 * @format
 */

const Constants = {
  editorName: process.env.REACT_APP_ATL_EDITOR_NAME || 'Editor',

  allowRegister: process.env.REACT_APP_ATL_EDITOR_ALLOW_REGISTER === 'true',

  hotjarId: process.env.REACT_APP_HOTJAR_ID ? parseInt(process.env.REACT_APP_HOTJAR_ID, 10) : undefined,
  hotjarVersion: process.env.REACT_APP_HOTJAR_VERSION ? parseInt(process.env.REACT_APP_HOTJAR_VERSION, 10) : undefined,

  tosUrl: process.env.REACT_APP_CGU_URL || '',
  privacyUrl: process.env.REACT_APP_CONFIDENTIALITY_URL || '',

  mapsApiKey: process.env.REACT_APP_MAPS_API_KEY || '',

  firebase: {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    functionsRegion: process.env.REACT_APP_FUNCTIONS_REGION,
  },
};

export default Constants;
