/**
 * @flow
 *
 * @format
 */

import type { Store as ReduxStore } from 'redux';
import { FirebaseSingleton } from 'src/services/Firebase';
import * as roomActions from './types';

type Action = { type: string, payload: any };

export const roomFirebaseMidleware = (store: ReduxStore) => (next: (Action) => any) => (action: Action) => {
  const result = next(action);

  let currentItemId;
  let currentNodeId;

  if (action.payload && !action.payload.isImport) {
    switch (action.type) {
      case roomActions.ROOM_SET_ITEM:
        currentItemId = action.payload.itemId;
        currentNodeId = action.payload.nodeId;
        if (FirebaseSingleton.currentRoom) {
          // FirebaseSingleton.currentRoom.
          if (currentNodeId && currentItemId) {
            FirebaseSingleton.currentRoom.update({ currentNodeId, currentItemId });
          } else {
            FirebaseSingleton.currentRoom.child('currentItemId').remove();
            FirebaseSingleton.currentRoom.child('currentNodeId').remove();
          }
        }
        break;
      default:
        break;
    }
  }

  return result;
};
