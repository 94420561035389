/**
 * @flow
 * @format
 */

import * as React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

type Props = {
  next: () => void,
  t: (key: string) => string,
  teamsLength: number,
  isEditor: boolean,
  showLimitScenarios: boolean,
};

const OnboardingIntroduction = ({ isEditor, showLimitScenarios, teamsLength, next, t }: Props) => {
  const errorTeam: boolean = !isEditor && showLimitScenarios && teamsLength <= 1;
  return (
    <div className="p-4">
      <p>{t('screens.newScenario.onboarding.positionScreen.stepToFollow')}</p>
      <br />
      <p className="font-italic mb-4">{t('screens.newScenario.onboarding.positionScreen.allCanBeUpdated')}</p>
      <div className="d-flex flex-row justify-content-center m-4">
        <button type="button" className="btn btn-primary text-uppercase btn-lg" onClick={next} disabled={errorTeam}>
          {t('screens.newScenario.onboarding.letsGo')}
        </button>
      </div>
      {errorTeam && (
        <p className="font-weight-bold pl-2 text-danger">
          {t('screens.newScenario.onboarding.positionScreen.errorTeam')}
        </p>
      )}
    </div>
  );
};

export default compose(withTranslation('default'))(OnboardingIntroduction);
