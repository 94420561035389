/**
 * @flow
 *
 * @format
 */
import * as types from './types';

export const setItemReleaseCheck = (
  itemId: string,
  isValid: boolean,
  alerts: { level: string, message: string, extra?: any }[],
) => ({
  type: types.VALIDITY_SET_ITEM_VALID,
  payload: { itemId, isValid, alerts },
});

export const cleanupChecks = () => ({
  type: types.VALIDITY_CLEANUP,
  payload: {},
});
