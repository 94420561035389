/**
 * @flow
 *
 * @format
 */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ScenarioServiceHelper } from 'src/store/scenario';
import Loader from 'src/pages/components/loader';
import { withAuthorization, AuthenticatedCondition } from 'src/services/Session';
import { Claims } from 'src/constants/roles';
import { withUrlProperties } from 'src/pages/components/WithUrlProperties';

import Firebase, { withFirebase } from 'src/services/Firebase';
import * as Routes from 'src/constants/routes';
import { ScenarioList } from './components';

export type ScenariosDashboardScreenProps = {
  loadScenarioFromFirebase: ScenarioServiceHelper.loadScenarioFromFirebaseType,
  resetScenario: ScenarioServiceHelper.cleanupType,
  firebase: Firebase,
  engineVersion: number,
  editionLocale: string,
};

type State = {
  scenarioId?: string,
  loading: boolean,
  imported: boolean,
};

class ScenariosDashboardScreen extends React.PureComponent<ScenariosDashboardScreenProps, State> {
  static defaultProps = {};

  reader: ?FileReader = undefined;

  state = {
    imported: false,
    scenarioId: undefined,
    loading: false,
  };

  componentDidMount() {
    const { resetScenario } = this.props;
    if (resetScenario) {
      // We put it in a timeout to let the time to auto save the items if needed
      setTimeout(() => {
        resetScenario();
      }, 50);
    }
  }

  onSelectScenario = async (id) => {
    const scenarioId = id;
    const { loadScenarioFromFirebase, firebase, resetScenario, engineVersion, editionLocale } = this.props;
    this.setState({ loading: true });
    if (resetScenario) {
      resetScenario();
    }
    if (loadScenarioFromFirebase) {
      try {
        await loadScenarioFromFirebase(scenarioId, firebase, engineVersion, editionLocale);
        this.setState({
          scenarioId,
          imported: true,
          loading: false,
        });
      } catch (error) {
        console.error(error);
        this.setState({
          imported: false,
          loading: false,
        });
      }
    }
  };

  render() {
    const { scenarioId, imported, loading } = this.state;
    if (imported && scenarioId) {
      return <Redirect to={Routes.SCENARIO_EDITION.replace(':scenarioId', scenarioId)} />;
    }
    return (
      <div className="pageContainer">
        <div className="container-fluid component-controller">
          <ScenarioList onSelect={this.onSelectScenario} />
        </div>
        {loading && <Loader />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  engineVersion: state.configuration.engineVersion,
  editionLocale: state.preferences.editionLocale,
});

const mapDispatchToProps = {
  resetScenario: ScenarioServiceHelper.cleanup,
  loadScenarioFromFirebase: ScenarioServiceHelper.loadScenarioAsync,
};

export default withUrlProperties(
  withFirebase(
    withAuthorization(AuthenticatedCondition, [Claims.Editor, Claims.Translator, Claims.Moderator])(
      connect(mapStateToProps, mapDispatchToProps)(ScenariosDashboardScreen),
    ),
  ),
);
