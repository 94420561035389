/**
 * @flow
 *
 * @format
 */

export const ROOM_SET_ROOM = 'room/ROOM_SET_ROOM';
export const ROOM_SET_ITEM = 'room/ROOM_SET_ITEM';
export const ROOM_SET_GRAPH_OFFSET = 'room/ROOM_SET_GRAPH_OFFSET';
export const ROOM_SET_GRAPH_ZOOM = 'room/ROOM_SET_GRAPH_ZOOM';
