/**
 * @flow
 *
 * @format
 */
import * as React from 'react';
import ConfirmModal from './ConfirmModal';

const generateId = () =>
  Math.random()
    .toString(36)
    .slice(-8);

const ConfirmContext: Object = React.createContext(null);
export type ConfirmProps = {};
type state = { callbacks: any, id: string };
const withConfirm = (Component: React.AbstractComponent<any>) => {
  class WithConfirm extends React.Component<ConfirmProps, state> {
    modaleCallbacks = undefined;

    state = {
      callbacks: {},
      id: '',
    };

    componentDidMount = () => {
      this.setState({ id: generateId() });
    };

    modalMounted = (callbacks) => {
      this.setState({ callbacks });
    };

    componentWillUnmount = () => {
      delete this.state.callbacks;
    };

    alert = (message: string, buttons: { text: String, onPress: () => any }[]) => {
      if (this.state.callbacks) {
        this.state.callbacks.alert(message, buttons);
      }
    };

    render() {
      return (
        <ConfirmContext.Provider>
          <Component {...this.props} alert={this.alert} />
          <ConfirmModal id={this.state.id} onMounted={(callbacks) => this.modalMounted(callbacks)} />
        </ConfirmContext.Provider>
      );
    }
  }

  return WithConfirm;
};

export default withConfirm;
