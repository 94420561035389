/**
 * @flow
 *
 * @format
 */

export const ITEMS_CREATE = 'items/ITEMS_CREATE';
export const ITEMS_UPDATE = 'items/ITEMS_UPDATE';
export const ITEMS_UPDATE_POSITION = 'items/ITEMS_UPDATE_POSITION';
export const ITEMS_REMOVE = 'items/ITEMS_REMOVE';

export const ITEMS_ADD_TRIGGERED_ITEM = 'items/ITEMS_ADD_TRIGGERED_ITEM';
export const ITEMS_UPDATE_TRIGGERED_ITEM = 'items/ITEMS_UPDATE_TRIGGERED_ITEM';
export const ITEMS_MOVE_TRIGGERED_ITEM = 'items/ITEMS_MOVE_TRIGGERED_ITEM';
export const ITEMS_REMOVE_TRIGGERED_ITEM = 'items/ITEMS_REMOVE_TRIGGERED_ITEM';

export const ITEMS_CLEAN = 'items/ITEMS_CLEAN';
export const REFRESH_ALL_TRIGGERED_INFO = 'items/REFRESH_ALL_TRIGGERED_INFO';

// Discussions
// ******************************
export const DISCUSSION_ADD = 'items/DISCUSSION_ADD';
export const DISCUSSION_UPDATE = 'items/DISCUSSION_UPDATE';

export const DISCUSSION_ADD_ENTRY_POINT = 'items/DISCUSSION_ADD_ENTRY_POINT';
export const DISCUSSION_UPDATE_ENTRY_POINT = 'items/DISCUSSION_UPDATE_ENTRY_POINT';
export const DISCUSSION_MOVE_ENTRY_POINT = 'items/DISCUSSION_MOVE_ENTRY_POINT';
export const DISCUSSION_REMOVE_ENTRY_POINT = 'items/DISCUSSION_REMOVE_ENTRY_POINT';

// Message
// ******************************
export const DISCUSSION_ADD_MESSAGE = 'items/DISCUSSION_ADD_MESSAGE';
export const DISCUSSION_UPDATE_MESSAGE = 'items/DISCUSSION_UPDATE_MESSAGE';
export const DISCUSSION_UPDATE_MESSAGE_POSITION = 'items/DISCUSSION_UPDATE_MESSAGE_POSITION';
export const DISCUSSION_REMOVE_MESSAGE = 'items/DISCUSSION_REMOVE_MESSAGE';
// Answer
// ******************************
export const DISCUSSION_ADD_ANSWER_OPENED = 'items/DISCUSSION_ADD_ANSWER_OPENED';
export const DISCUSSION_UPDATE_ANSWER = 'items/DISCUSSION_UPDATE_ANSWER';
export const DISCUSSION_UPDATE_ANSWER_POSITION = 'items/DISCUSSION_UPDATE_ANSWER_POSITION';
export const DISCUSSION_REMOVE_ANSWER = 'items/DISCUSSION_REMOVE_ANSWER';
// AnswerOpened
// ******************************
export const DISCUSSION_ADD_ANSWER = 'items/DISCUSSION_ADD_ANSWER';
export const DISCUSSION_UPDATE_ANSWER_OPENED = 'items/DISCUSSION_UPDATE_ANSWER_OPENED';
export const DISCUSSION_UPDATE_ANSWER_OPENED_POSITION = 'items/DISCUSSION_UPDATE_ANSWER_OPENED_POSITION';
export const DISCUSSION_REMOVE_ANSWER_OPENED = 'items/DISCUSSION_REMOVE_ANSWER_OPENED';
// Message triggeredItem
// ******************************
export const DISCUSSION_ADD_MESSAGE_TRIGGERED_ITEM = 'items/DISCUSSION_ADD_MESSAGE_TRIGGERED_ITEM';
export const DISCUSSION_UPDATE_MESSAGE_TRIGGERED_ITEM = 'items/DISCUSSION_UPDATE_MESSAGE_TRIGGERED_ITEM';
export const DISCUSSION_UPDATE_MESSAGE_TRIGGER_POSITION = 'items/DISCUSSION_UPDATE_MESSAGE_TRIGGER_POSITION';
export const DISCUSSION_REMOVE_MESSAGE_TRIGGERED_ITEM = 'items/DISCUSSION_REMOVE_MESSAGE_TRIGGERED_ITEM';
// Discussion links
// ******************************
export const DISCUSSION_LINK_NEXT_MESSAGE = 'items/DISCUSSION_LINK_NEXT_MESSAGE';
export const DISCUSSION_LINK_MESSAGE_ANSWER = 'items/DISCUSSION_LINK_MESSAGE_ANSWER';
export const DISCUSSION_LINK_MESSAGE_TRIGGERED_ITEM = 'items/DISCUSSION_LINK_MESSAGE_TRIGGERED_ITEM';

export const DISCUSSION_UNLINK_NEXT_MESSAGE = 'items/DISCUSSION_UNLINK_NEXT_MESSAGE';
export const DISCUSSION_UNLINK_MESSAGE_ANSWER = 'items/DISCUSSION_UNLINK_MESSAGE_ANSWER';
export const DISCUSSION_UNLINK_MESSAGE_TRIGGERED_ITEM = 'items/DISCUSSION_UNLINK_MESSAGE_TRIGGERED_ITEM';
