/**
 * @flow
 *
 * @format
 */
import * as React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import Firebase, { withFirebase } from 'src/services/Firebase';

export interface FirebaseImageViewProps {
  firebasePath: string;

  firebase: Firebase;

  firebaseRef: any;

  url: string;

  alt: string;

  style: any;
}

interface State {
  url: string;
}

class FirebaseImageView extends React.PureComponent<FirebaseImageViewProps, State> {
  state = {
    url: '',
  };

  componentDidMount = () => {
    this.refreshImage(this.props);
  };

  componentDidUpdate = (oldProps: FirebaseImageViewProps) => {
    const { firebasePath, firebaseRef, url, firebase } = this.props;
    const { firebasePath: oldPath, firebaseRef: oldRef, url: oldUrl, firebase: oldFb } = oldProps;
    if (firebasePath !== oldPath || firebaseRef !== oldRef || url !== oldUrl || firebase !== oldFb) {
      this.refreshImage(this.props);
    }
  };

  refreshImage = async (props: FirebaseImageViewProps) => {
    const { firebasePath, firebaseRef, url, firebase } = props;
    if (url) {
      this.setState({ url });
    } else {
      let ref = firebaseRef;
      if (firebasePath && !ref) {
        ref = firebase.imageUrl(firebasePath);
      }
      try {
        // eslint-disable-next-line no-param-reassign
        const url = await ref.getDownloadURL();
        this.setState({ url });
      } catch (error) {
        console.warn('Unable to load bg asset', error);
      }
    }
  };

  render() {
    const { alt, style } = this.props;
    const { url } = this.state;
    return <img src={url} alt={alt} style={style} />;
  }
}

export default withFirebase(FirebaseImageView);
