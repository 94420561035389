/**
 * @flow
 *
 * @format
 */
import { createNamedReducer } from 'redux-named-reducers';
import type { OnboardingOptions } from 'src/data/types/OnboardingOptions';
import * as types from './types';

export type OnboardingReducerState = OnboardingOptions;
export const OnboardingReducerDefaultState = {
  coordinate: {
    latitude: 0,
    longitude: 0,
  },
  teamInputId: '',
  nameScenario: '',
  nameCheckbox: false,
  pitchScenario: '',
  pitchCheckbox: false,
  missionScenario: '',
  missionCheckbox: false,
  year: '',
  subtitle: {
    fr: '',
    en: '',
  },
};

const reducer = (
  prevState: OnboardingReducerState = OnboardingReducerDefaultState,
  action: { type: string, payload: any },
) => {
  let state;
  switch (action.type) {
    case types.ONBOARDING_UPDATE_COORDINATES:
      state = {
        ...prevState,
        coordinate: action.payload,
      };
      break;
    case types.ONBOARDING_UPDATE_NAME_PITCH_MISSION:
      state = {
        ...prevState,
        [`${action.payload.fieldName}Scenario`]: action.payload.valueString,
        [`${action.payload.fieldName}Checkbox`]: action.payload.valueCheckbox,
      };
      break;
    case types.ONBOARDING_UPDATE_DEPARTURE:
      state = {
        ...prevState,
        year: action.payload.departure,
        subtitle: {
          fr: `Année de retour : ${action.payload.departure}`,
          en: `Year of return: ${action.payload.departure}`,
        },
      };
      break;
    case types.ONBOARDING_UPDATE_TEAM_ID:
      state = {
        ...prevState,
        teamInputId: action.payload.teamInputId,
      };
      break;
    case types.ONBOARDING_RESET_VALUES:
      state = OnboardingReducerDefaultState;
      break;
    default:
      state = prevState;
      break;
  }
  return state;
};

export const OnboardingReducerKey = 'onboarding';

export default createNamedReducer({
  moduleName: OnboardingReducerKey,
  reducer,
});
