/**
 * @flow
 *
 * @format
 */
import BaseItem, { ItemTypes } from './BaseItem';

/* ***************************
  CLASS
*************************** */
export type AppCustom = {};

export default class CustomItem extends BaseItem {
  customContent: string;

  additionalInfo: ?any;

  constructor(json: any) {
    if (json instanceof CustomItem) {
      super(json, true);
    } else {
      super(json, false);
    }
    const { type, meta, customContent, additionalInfo, ...newMeta } = json;
    this.type = ItemTypes.Custom;
    this.additionalInfo = additionalInfo ? { ...additionalInfo } : {};
    this.customContent = customContent;
    this.setMeta({ ...json.meta, ...newMeta });
  }

  serializeInheritedFieldsForApp() {
    const res = super.serializeInheritedFieldsForApp();
    res.customContent = this.customContent;
    res.additionalInfo = { ...this.additionalInfo };
    return res;
  }

  serializeInheritedFields() {
    const res = super.serializeInheritedFields();
    res.customContent = this.customContent;
    res.additionalInfo = { ...this.additionalInfo };
    return res;
  }
}
